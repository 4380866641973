<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <div class="row">
    <div class="col-md-12 text-center payment-success">
      <img class="w-25" src="../../../../../assets/images/payment-success/{{companyGroupCode}}-payment-success.svg">
      <h1 class="mb-5 mt-4">{{ 'THANKS_FOR_PAYING' | translate }}</h1>
        <div class="d-flex justify-content-center">
          <button [routerLink]="'/' + companyGroupCode + '/financial/invoices'" *ngIf="page === 'invoices' && showFinancialButtons" class="btn btn-primary mx-2">{{ 'BACK_TO_INVOICES' | translate }}</button>
          <button [routerLink]="'/' + companyGroupCode + '/financial/openorders'" *ngIf="page === 'checkout' && showFinancialButtons" class="btn btn-primary mx-2">{{ 'TO_OPEN_ORDERS' | translate }}</button>
          <button [routerLink]="'/' + companyGroupCode + '/home'" class="btn btn-secondary mx-2">{{ 'BACK_TO_HOME' | translate }}</button>
        </div>
    </div>
  </div>
</div>
