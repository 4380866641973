import {
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { SearchService } from 'src/app/services/search.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SideBarComponent } from '../side-bar/side-bar.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CommonModule, Location, PlatformLocation } from '@angular/common';
import { ArticleDetailsComponent } from 'src/app/components/article-components/article-details/article-details.component';
import * as _ from 'underscore';
import { ApplicationService } from 'src/app/services/application.service';
import { fromEvent, Subject, timer } from 'rxjs';
import { BasketService } from 'src/app/services/basket.service';
import { AlertService } from 'src/app/services/alert.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddAllModalComponent } from 'src/app/components/modal-components/add-all-modal/add-all-modal.component';
import { CompareArticlesComponent } from 'src/app/components/compare-articles-components/compare-articles.component';
import { ArticleGroup } from 'src/app/interfaces/articleGroup';
import { Article } from 'src/app/interfaces/article';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { SearchResults } from 'src/app/interfaces/search-results';
import { GroupCollection } from 'src/app/interfaces/group-collection';
import { PriceRange } from 'src/app/interfaces/price-range';
import { SearchFilter } from 'src/app/interfaces/search-filter';
import { SuggestTermsComponent } from '../suggest-terms/suggest-terms.component';
import { FavoritesService } from 'src/app/services/favorites.service';
import { ArticleCollectorService } from 'src/app/services/article-collector.service';
import { DefaultFilters } from 'src/app/interfaces/default-filters';
import { TrackingService } from 'src/app/services/tracking.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PreorderContainerComponent } from '../../preorder-components/preorder-container/preorder-container.component';
import { ReplenishmentService } from 'src/app/services/replenishment.service';
import { ConfirmDialogComponent } from '../../modal-components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { PreorderService } from 'src/app/services/preorder.service';
import { HelperService } from 'src/app/services/helper.service';
import { ArticleService } from 'src/app/services/article.service';
import { StockNotificationArticle } from 'src/app/interfaces/stockNotifications/stock-notification-article';
import { StockweekFilter } from 'src/app/interfaces/stockweek-filter';
import { ReplenishmentSelectionModalComponent } from '../../modal-components/replenishment-selection-modal/replenishment-selection-modal.component';
import { AccountService } from 'src/app/services/account.service';
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
} from '@angular/animations';
import { StockNotificationsList } from 'src/app/interfaces/stockNotifications/stock-notification';
import { RefineKeyword } from 'src/app/interfaces/refine-keyword';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0.5 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
    trigger('flyIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-100px)' }),
        animate(
          '400ms cubic-bezier(0.35, 0, 0.25, 1)',
          style({ opacity: 1, transform: 'translateX(0)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, 'z-index': 0 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('pageAnimations', [
      transition(':enter', [
        query(
          '.card',
          [
            style({ opacity: 0, transform: 'translateY(-100px)' }),
            stagger(30, [
              animate(
                '400ms cubic-bezier(0.35, 0, 0.25, 1)',
                style({ opacity: 1, transform: 'none' })
              ),
            ]),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SearchComponent implements OnInit {
  @Output() resultsUpdated: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(SideBarComponent) sideBar: SideBarComponent;
  @ViewChild(ArticleDetailsComponent)
  articleDetailsComponent: ArticleDetailsComponent;
  @Input() showSideBar = true;
  @Input() showViewOptions = true;
  @Input() showViewPaginator = true;
  @Input() url: string;
  @Input() staticPaginator = false;
  @Input() showWidgets = true;
  @ViewChild(SuggestTermsComponent)
  suggestTerms: SuggestTermsComponent;
  selectedArticle: any;
  articles: Article[] = [];
  groups: ArticleGroup[];
  selectedGroups: GroupCollection;
  searchText = '';
  page = 1;
  count = 0;
  collectionSize = 0;
  priceRangeData: PriceRange;
  availableFilters: SearchFilter[];
  filters: {};
  searchVisible = false;
  selectedIndex: number;
  pageSize = 24;
  searchXhr: any = null;
  lastLocation: any;
  viewType = 'card';
  showAddAllButton: boolean;
  selectedForBasket: any = {};
  selectedForBasketCount = 0;
  sorting = '1';
  companyGroupCode = '';
  lastScrollPos = 0;
  selectedTab = 'search';
  dataLoaded = new Subject<any>();
  dataLoadedEmitted$ = this.dataLoaded.asObservable();
  loaded = true;
  subscriptions: any[];
  preorderVisited: boolean;
  imageBankTab: string = null;
  leaseRequest: boolean;
  state = 'fixed';
  obs: any;
  autoLoadPreorder = true;
  stockLoaded = false;
  pageOfResults: number;
  checkedUnsaved: boolean;
  favoritesType = 'main';
  stockweekFilter: StockweekFilter;
  preorderType = 'Bicycles';
  replenishmentNotSaved = false;
  replenishmentHelpId = '960422232';
  keyDown: any;
  keyUp: any;
  replenishmentAvailable: boolean;
  showSaveReplenishment = this.accountService.isInternal();
  @Input() showSearchTabs = true;
  filterSearchText = '';
  sidebarTop: number;
  navbarHeight: number;
  showPromotions = false;
  animationsDisabled = false;
  filterSearchKeepFilters: boolean;
  groupFiltered: boolean;

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private applicationService: ApplicationService,
    private basketService: BasketService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef,
    private favoritesService: FavoritesService,
    private platformLocation: PlatformLocation,
    public articleCollectorService: ArticleCollectorService,
    private trackingService: TrackingService,
    public replenishmentService: ReplenishmentService,
    private cdRef: ChangeDetectorRef,
    public modal: NgbActiveModal,
    public preorderService: PreorderService,
    private helperService: HelperService,
    private articleService: ArticleService,
    private alertService: AlertService,
    private accountService: AccountService
  ) {
    const self = this;
    this.subscriptions = [];

    this.platformLocation.onPopState(() => {
      self.searchVisible = true;
      const currentUrl = window.location.href.replace(
        window.location.origin,
        ''
      );
      if (this.lastLocation !== currentUrl) {
        this.getFiltersFromUrl(false);
        this.loadResults();
      }
      setTimeout(() => {
        this.scrollToTop(self.lastScrollPos);
      }, 0);
    });

    this.showSearchTabs = !this.applicationService.isPartnerPortal();

    // Subscriptions
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        if (params['text']) {
          this.setSearchTab();
          this.searchText = params['text'];
          this.selectedGroups = new GroupCollection();
          this.setUrl(this.sideBar ? this.sideBar.selectedFilters : {}, false);
        }

        this.groupFiltered = false;
        if (window.location.hash.includes('groupFiltered')) {
          this.groupFiltered = true;
        }
      })
    );

    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        if (params['keyword']) {
          this.setSearchTab();
          this.searchText = params['keyword'];
          this.selectedGroups = new GroupCollection();
          this.setUrl(this.sideBar ? this.sideBar.selectedFilters : {}, false);
        }
      })
    );

    this.subscriptions.push(
      router.events.subscribe((val) => {
        if (window.location.href.includes('articleDetails')) {
          this.sorting = '1';
        }

        if (
          window.location.href.includes('&favorites') &&
          this.selectedTab !== 'favorites' &&
          !this.searchText
        ) {
          this.selectedTab = 'favorites';
          if (this.router.url.includes('stocklist')) {
            this.favoritesType = 'stocklist';
          }
          this.searchVisible = true;
          this.getResults(true, 1);
        }
      })
    );

    if (this.router.url.indexOf('articleDetails') >= 0) {
      this.searchVisible = false;
    }

    this.subscriptions.push(
      this.location.subscribe((loc) => {
        if (loc.url === '' || loc.url.indexOf('search') >= 0) {
        } else {
          this.getLastScrollPos();
          self.searchVisible = false;
        }
      })
    );

    this.subscriptions.push(
      this.applicationService.emitChangeCompanyGroupChanged$.subscribe(() => {
        this.selectedGroups = new GroupCollection();
        this.stockLoaded = true;
        this.filters = {};
      })
    );

    this.subscriptions.push(
      this.searchService.showDetailsEmitted$.subscribe((article) => {
        this.searchVisible = false;
        this.showArticleDetails(article, null);
      })
    );

    this.subscriptions.push(
      this.searchService.groupsEmitted$.subscribe((groups) => {
        this.searchText = '';
        this.page = 1;
        this.setSearchTab();
        this.getFiltersFromUrl(true, true);
      })
    );

    this.subscriptions.push(
      this.searchService.emitChangeFilterSource$.subscribe((searchData) => {

        const text = searchData['keyword'];
        const group = searchData['group'];

        this.page = 1;
        if (text || text === '') {
          this.searchText = text;
        }

        this.filters = {};
        if (typeof this.sideBar !== 'undefined') {
          this.sideBar?.resetFilters();
        }
        this.searchVisible = true;
        const isFavorites = this.selectedTab === 'favorites';
        const selectedGroups = new GroupCollection();
        selectedGroups.article_group_codes = [group];

        this.setUrl(this.sideBar?.selectedFilters, true);

        if (isFavorites) {
          this.selectedTab = 'search';
        }
        this.getFiltersFromUrl(true);
      })
    );

    // Click from breadcrumbs
    this.subscriptions.push(
      this.searchService.emitBreadcrumbsSource$.subscribe(() => {
        this.page = 1;
        this.searchText = '';
        this.filters = {};
        this.searchVisible = true;
        const isFavorites = this.selectedTab === 'favorites';
        if (isFavorites) {
          this.selectedTab = 'search';
        }
        this.getFiltersFromUrl(true);
      })
    );

    this.subscriptions.push(
      this.searchService.changeEmitted$.subscribe((text) => {
        // Show search results
        this.searchVisible = true;

        if (this.lastLocation) {
          this.location.go(this.lastLocation);
        }

        if (
          text ||
          text === '' ||
          (this.articles && !this.articles.length) ||
          this.selectedTab === 'favorites'
        ) {
          this.searchText = text;
          this.setSearchTab();

          // Reset groups when text search
          if (text) {
            this.selectedGroups = new GroupCollection();
          }
          if (this.sideBar) {
            this.setUrl(this.sideBar.selectedFilters, true);
          }
          this.getResults(true, 1);
        } else if (text === '') {
          self.searchText = '';
        } else {
          this.loadResults();
        }

        setTimeout(() => {
          // Scroll to last position
          this.scrollToTop(self.lastScrollPos);
        }, 0);
      })
    );

    this.subscriptions.push(
      this.searchService.showSearchEmitted$.subscribe((visible) => {
        if (visible) {
          this.searchVisible = true;
          if (this.lastLocation) {
            this.location.go(this.lastLocation);
            setTimeout(() => {
              this.scrollToTop(this.lastScrollPos);
            });
          }
        }
      })
    );

    if (this.route.snapshot.data['type'] === 'viewArticle') {
      this.searchVisible = false;
    } else if (this.router.url.indexOf('search') >= 0) {
      this.selectedTab = 'search';
      this.searchVisible = true;
    }

    if (window.location.href.includes('parts')) {
      this.preorderType = 'Parts';
    }
  }

  setSearchTab() {
    switch (this.selectedTab) {
      case 'imagebank':
      case 'preorder':
      case 'replenishment':
        break;
      default:
        this.selectedTab = 'search';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription && Object.keys(subscription)) {
        subscription.unsubscribe();
      }
    });

    this.searchService.setBrandAndArticleType([], '');

    this.stockLoaded = false;

    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  onWindowScroll(e) {
    if (!this.staticPaginator) {
      const footer = document.getElementById('footer');
      const footerHeight = footer ? footer.clientHeight : 0;

      const h = document.documentElement;
      const b = document.body;
      const st = 'scrollTop';
      const sh = 'scrollHeight';

      const percent =
        ((h[st] || b[st]) /
          ((h[sh] || b[sh]) - (h.clientHeight + footerHeight))) *
        100;

      if (percent >= 100 || percent < 0) {
        this.state = 'static';
      } else {
        this.state = 'fixed';
      }

      if (localStorage.getItem('navbar-not-fixed') === '1') {
        if (h[st] < this.navbarHeight) {
          this.sidebarTop = this.navbarHeight - h[st];
          document.documentElement.style.setProperty(
            '--sideBarTop',
            `${this.sidebarTop + 55}px`
          );
        } else {
          this.sidebarTop = 0;
          document.documentElement.style.setProperty('--sideBarTop', '55px');
        }
      }
    } else {
      this.state = 'static';
    }
  }

  ngOnInit() {
    let waiting = false;
    let endScrollHandle;


    this.obs = fromEvent(document, 'scroll').subscribe((e) => {
      if (waiting) {
        return;
      }

      waiting = true;

      // clear previous scheduled endScrollHandle
      clearTimeout(endScrollHandle);
      this.onWindowScroll(e);

      setTimeout(function () {
        waiting = false;
      }, 50);

      endScrollHandle = setTimeout(() => {
        this.onWindowScroll(e);
      }, 100);
    });

    if (this.searchVisible) {
      this.lastLocation = window.location.href.replace(
        window.location.origin,
        ''
      );
    }

    // Get selected tab
    this.selectedTab = this.route.snapshot.data['tab']
      ? this.route.snapshot.data['tab']
      : 'search';

    this.viewType = this.url
      ? 'card'
      : this.applicationService.getSelectedViewType(this.viewType);
    this.pageSize = !this.router.url.indexOf('favorites')
      ? Number(
          this.applicationService.getSelectedPageSize(String(this.pageSize))
        )
      : 24;
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();

    if (this.router.url.indexOf('articleDetails') < 0) {
      this.searchVisible = true;

      if (!this.articles.length) {
        this.loadResults();
      }
    }

    // Set navigation events
    this.handleNavigation();

    this.applicationService.searchMenuActive.subscribe((open) => {
      if (open) {
        this.keyUp.unsubscribe();
        this.keyDown.unsubscribe();
      } else {
        this.handleNavigation();
      }
    });

    this.basketService.allAllChangeEmitted$.subscribe((response) => {
      this.selectedForBasket = [];
      this.selectedForBasketCount = 0;
      this.showAddAllButton = false;
    });

    this.articleCollectorService.changeEmitted$.subscribe(() => {
      this.countSelection();
    });

    // this.subscriptions.push(this.router.events.subscribe((data: any) => {
    //     this.getFiltersFromUrl(true);
    // }))
    this.cdRef.detectChanges();
  }

  ngAfterViewChecked() {
    this.setSideBarTop();
  }

  // Card - treeview
  setViewType(type) {
    this.viewType = type;

    if (type === 'swiper') {
      this.trackingService.easterEgg();
    }

    this.applicationService.saveSetting('viewType', type);
  }

  setSideBarTop() {
    try {
      if (!this.navbarHeight) {
        const mainNavHeight = document.getElementById('header').offsetHeight;
        const userBarHeight =
          document.getElementById('userBar-menu').offsetHeight;
        this.navbarHeight = mainNavHeight + userBarHeight;
        document.documentElement.style.setProperty(
          '--sideBarTop',
          localStorage.getItem('navbar-not-fixed') === '1'
            ? `${mainNavHeight + userBarHeight}px`
            : `${mainNavHeight + userBarHeight + 55}px`
        );
      }
    } catch (err) {}
  }

  handleNavigation() {
    let searchVisible = false;

    this.keyDown = fromEvent(document.body, 'keydown').subscribe((e) => {
      searchVisible = window.location.href.indexOf('search') >= 0;
      const focusedElement = document.activeElement;

      if (searchVisible && focusedElement === document.body) {
        if (e['code'] === 'ArrowLeft' && this.page > 1) {
          this.page--;
        }
        if (e['code'] === 'ArrowRight') {
          this.page++;
        }
      }
    });

    this.keyUp = fromEvent(document.body, 'keyup').subscribe((e) => {
      if (searchVisible) {
        if (e['code'] === 'ArrowLeft' || e['code'] === 'ArrowRight') {
          this.setUrl(this.sideBar.selectedFilters, true);
          this.loadResults();
        }
      }
    });

    this.subscriptions.push(this.keyDown);
    this.subscriptions.push(this.keyUp);
  }

  private getCachedPage() {
    const page = window.location.href;
    if (
      this.searchService.cachedResults[page] &&
      this.searchService.cachedResults[page].results.length
    ) {
      return this.searchService.cachedResults[page];
    } else {
      return null;
    }
  }

  // Get results from cache if available - else get from api
  loadResults() {
    // try to load from cache
    if (this.getCachedPage() && !this.url) {
      this.processResults(this.getCachedPage());

      setTimeout(() => {
        this.dataLoaded.next(0);
        // Get filters
        this.getFiltersFromUrl(false);
      }, 0);

      this.loaded = true;
    } else {
      // Get filters and reload
      this.getFiltersFromUrl(true);
    }
  }

  // Get results from api
  getResults(reset, scrollTop, showInstant = true) {
    if (reset || !this.page) {
      this.page = 1;
    }
    const self = this;
    this.loaded = false;

    if (self.searchXhr) {
      this.searchXhr.unsubscribe();
      this.applicationService.hideLoader();
    }

    if (this.selectedTab === 'replenishment') {
      this.filters['PIM-FOCUS-MODEL'] = {
        id: 'ACCSTSF',
        options: [{ id: 'N', value: 'checked' },{ id: 'A', value: 'checked' }],
      };
    }

    this.applicationService.showLoader(true);

    return new Promise((resolve, reject) => {
      if (self.selectedTab !== 'favorites') {
        self.searchXhr = self.searchService
          .search(
            self.searchText,
            self.page,
            self.pageSize,
            self.sorting,
            self.filters,
            self.selectedGroups,
            self.selectedTab,
            false,
            self.selectedTab === 'preorder' ? this.preorderType : null,
            self.stockweekFilter,
            this.searchService.brands,
            this.searchService.articleType
          )
          .subscribe((response: ApiResponse) => {
            //self.spinner.hide();
            self.loaded = true;
            self.applicationService.hideLoader();
            if (response.success) {
              const searchResults: SearchResults = new SearchResults(
                response.result
              );

              // One result -> redirect
              if (
                showInstant &&
                this.selectedTab === 'search' &&
                searchResults.results.length === 1 &&
                self.selectedTab !== 'preorder' &&
                self.selectedTab !== 'replenishment' &&
                self.searchText
              ) {
                const firstArticle = searchResults.results[0] as Article;
                const selectedArticleCode = String(
                  self.searchText
                ).toUpperCase();
                if (
                  firstArticle &&
                  firstArticle.article_codes &&
                  firstArticle.article_codes.indexOf(selectedArticleCode) >= 0
                ) {
                  firstArticle.single_article_code = selectedArticleCode;
                }
                self.showArticleDetails(firstArticle, 0, false);
                resolve('updated');
                return false;
              }

              self.processResults(searchResults);

              setTimeout(() => {
                self.dataLoaded.next(searchResults.count);
                if (
                  self.selectedTab === 'search' ||
                  self.selectedTab === 'replenishment' ||
                  self.selectedTab === 'preorder'
                ) {
                  self.searchService.getNettPrices(self.articles);
                }
              }, 0);

              if ((scrollTop && scrollTop >= 0) || scrollTop === 0) {
                self.scrollToTop(scrollTop);
              }

              // Show suggestions when no results
              if (self.suggestTerms) {
                if (self.count === 0) {
                  self.suggestTerms.searchTerm = self.searchText;
                  self.suggestTerms.getSuggestions(true);
                  self.suggestTerms.showTerms = true;
                } else {
                  self.suggestTerms.showTerms = false;
                }
              }
            }
            self.getFiltersFromUrl(false);
            resolve('updated');
          });
      } else {
        if (this.favoritesType === 'main') {
          const inputData = {
            page_size: self.pageSize,
            page: self.page,
            sort_column: self.sorting,
            type: this.favoritesType,
          };
          self.loaded = false;
          self.favoritesService
            .getFavorites(inputData)
            .subscribe((response: ApiResponse) => {
              // self.spinner.hide();
              self.loaded = true;
              self.applicationService.hideLoader();
              if (this.helperService.checkResponse(response)) {
                self.articles = Object.values(response.result.results);
                self.collectionSize = response.result.count;
                self.count = response.result.count;
                setTimeout(() => {
                  self.loaded = true;
                  self.dataLoaded.next(self.count);
                  self.searchService.getNettPrices(self.articles);
                }, 0);
              }
            });
        } else {
          self.loaded = false;
          const inputData = {
            page_size: self.pageSize,
            page: self.page,
          };
          this.articleService
            .getArticleStockNotificationList(inputData)

            .subscribe((apiResponse: ApiResponse) => {
              this.loaded = true;
              this.applicationService.hideLoader();
              if (this.helperService.checkResponse(apiResponse)) {
                this.count = apiResponse.result.count;
                this.collectionSize = apiResponse.result.total_item_count;
                this.pageSize = apiResponse.result.page_size;
                this.page = apiResponse.result.page_number;
                this.articles = _.pluck(
                  apiResponse.result.array,
                  'product_details'
                );
                this.dataLoaded.next(this.count);
              }
            });
        }
      }
    });
  }

  processResults(searchResults: SearchResults) {
    this.count = searchResults.count;
    this.pageSize = searchResults.pagesize;
    this.collectionSize = this.count;
    this.priceRangeData = searchResults.pricerange;
    this.availableFilters = searchResults.filters;
    this.groups = searchResults.groups;
    this.pageOfResults = searchResults.page;
    if (this.count > 0) {
      this.articles = searchResults.results;
    } else {
      this.articles = [];
      this.groups = [];
    }

    if (this.sideBar) {
      setTimeout(() => {
        this.sideBar.renderSelectedFilters(searchResults.filters);
      });
    }
    this.searchService.cachedResults[window.location.href] = searchResults;
    this.trackingService.searchResults(
      this.articles,
      this.companyGroupCode,
      this.groups,
      this.availableFilters
    );

    //  Get dealer stock data
    if (this.selectedTab === 'replenishment' && !this.stockLoaded) {
      this.replenishmentService.getCurrentStock();
      this.stockLoaded = true;
    }
  }

  scrollToTop(position = 1) {
    this.applicationService.scrollToTop(position);
  }

  onPager(page, preorderChecked?) {
    if (this.selectedTab === 'preorder' && !preorderChecked) {
      this.checkPreorderSelection(page);
    } else {
      if (this.selectedTab === 'preorder') {
        this.checkedUnsaved = false;
      }

      // Track action
      let type = 'number';
      if (page > this.page) {
        type = 'next';
      } else if (page < this.page) {
        type = 'prev';
      }
      this.trackingService.searchPaginate(type, page);
      if (this.sideBar) {
        this.setUrl(this.sideBar.selectedFilters, true);
      }
      this.loadResults();
      this.scrollToTop();
    }
  }

  checkPreorderSelection(page) {
    this.checkedUnsaved = false;
    const unsavedPreorderTables = document.getElementsByClassName('unsaved');
    if (unsavedPreorderTables.length && this.preorderService.unsaved) {
      this.checkedUnsaved = true;
      const modalRef = this.modalService.open(ConfirmDialogComponent, {
        centered: true,
        size: 'sm',
        windowClass: 'confirmDialog',
        container: '#modalContainer',
      });
      modalRef.componentInstance.isConfirm = true;
      modalRef.componentInstance.cancelText = 'BACK_TO_PAGE';
      modalRef.componentInstance.confirmText = 'CONTINUE_ANYWAY';
      modalRef.componentInstance.title =
        this.translateService.instant('CAUTION');
      modalRef.componentInstance.body = this.translateService.instant(
        'PREORDER_CLOSE_WISHLIST'
      );
      modalRef.componentInstance.confirmClicked.subscribe(() => {
        this.page = page;
        this.onPager(page, true);
        this.modal.close();
      });
      timer(0).subscribe(() => {
        this.page = this.pageOfResults;
      });
    } else {
      this.onPager(page, true);
    }
  }

  pageSizeChange(value) {
    this.page = 1;
    this.pageSize = value;
    this.setUrl(this.sideBar.selectedFilters, true);
    this.getResults(false, 1);
    this.applicationService.saveSetting('pageSize', value);
  }

  prevPage() {
    this.page--;
    this.setUrl(this.filters, true);
    this.getResults(false, 1);
  }

  nextPage() {
    this.page++;
    this.setUrl(this.filters, true);
    this.getResults(false, 1);
  }

  sortingChange(value) {
    this.sorting = value;
    this.getResults(false, 1);
    this.setUrl(this.sideBar.selectedFilters, true);
  }

  searchRequest(searchText: any) {
    this.searchText = searchText;
    this.getResults(true, 1);
  }

  // Set filters (received from sidebar)
  setFilters(filterData) {
    const filters = {};

    Object.keys(filterData).forEach(function (key) {
      if (Object.keys(filterData[key]).length) {
        filters[key] = {
          id: key,
          options: [],
        };

        Object.keys(filterData[key]).forEach(function (filterKey) {
          if (key !== 'PRICE') {
            if (filterData[key][filterKey]) {
              filters[key]['options'].push({
                id: decodeURI(filterKey),
                value: 'checked',
              });
            }
          } else {
            filters[key]['options'] = filterData[key]['options'];
          }
        });
      }
    });

    this.filters = filters;
    this.searchVisible = true;
  }

  // Receive filter update from sidebar
  filtersUpdated(filterData) {
    this.page = 1;
    this.setFilters(filterData['filters']);

    // Reset brand / article type (logo brand click)
    if (filterData['reset']) {
      this.searchService.articleType = '';
      this.searchService.brands = [];
    }

    this.setUrl(filterData['filters'], true);

    if (filterData['updateResults']) {
      this.getResults(true, 1, false);
    }
  }

  groupsUpdated(groupsData) {
    this.selectedGroups = new GroupCollection();
    this.page = 1;
    this.setGroup(groupsData, 'product_group_codes');
    this.setGroup(groupsData, 'article_group_codes');
    this.setGroup(groupsData, 'article_sub_group_codes');
    this.setGroup(groupsData, 'variation_group_code');
    this.trackingService.filterSearchGroup(
      groupsData['product_group_codes'],
      groupsData['article_group_codes'],
      groupsData['article_sub_group_codes'],
      groupsData['variation_group_code']
    );
    this.setUrl(this.sideBar.selectedFilters, true);
    this.getResults(true, 1, false);
  }

  stockweeksUpdated(stockweeks: StockweekFilter) {
    this.stockweekFilter = stockweeks;
    this.setUrl(this.sideBar.selectedFilters, true);
    this.getResults(true, 1);
  }

  setGroup(data, key) {
    try {
      if (data[key]) {
        this.selectedGroups[key] = [data[key]];
      }
    } catch (err) {
      console.log(err)
    }
  }

  // Set url based on selected filters etc
  setUrl(filterData, setLocation) {
    if (typeof filterData !== 'undefined') {
      let queryString: String = '';
      let hasGroup: boolean;
      delete filterData['CATEGORIES'];
      delete filterData['categories'];
      delete filterData['PREORDERTYPE'];

      Object.keys(filterData).forEach(function (key) {
        if (key === 'PRICE' && filterData[key]['options']) {
          let substr = `&PRICE=${filterData[key]['options'][0]['value']},${filterData[key]['options'][1]['value']}`;
          queryString += substr;
        } else {
          const filter = filterData[key];
          if (Object.keys(filter).length && key !== 'undefined') {
            let substr = (hasGroup ? '&' : '') + key + '=';
            let hasKey = false;
            hasGroup = true;
            Object.keys(filter).forEach(function (filterKey) {
              if (filter[filterKey]) {
                // Check if filter key contains a comma, and encode it
                if (
                  filterKey.indexOf(',') >= 0 ||
                  filterKey.indexOf('/') >= 0
                ) {
                  filterKey = encodeURIComponent(filterKey);
                }
                substr += (hasKey ? ',' : '') + filterKey;
                hasKey = true;
              }
            });
            queryString += substr;
          }
        }
      });

      const searchQstr = this.searchText ? `?keyword=${this.searchText}` : '';
      if (this.page > 1) {
        queryString += '&page=' + String(this.page ? this.page : 1);
      }
      queryString += this.setGroupsQueryStr();

      if (!queryString.includes('PAGESIZE')) {
        queryString += '&pageSize=' + String(this.pageSize ? this.pageSize : 1);
      }

      if (this.searchService.brands.length) {
        queryString += '&brands=' + this.searchService.brands.join(',');
      }

      if (this.searchService.articleType) {
        queryString += '&article_type=' + this.searchService.articleType;
      }

      let url = '/' + this.applicationService.getSelectCompanyGroupCode();

      switch (this.selectedTab) {
        case 'search':
          url +=
            '/search' +
            searchQstr +
            (queryString ? '#filter/' + queryString : '');
          this.imageBankTab = null;
          break;
        case 'favorites':
          const base =
            this.favoritesType === 'main' ? 'favorites' : 'stocknotifications';
          url += `/${base}/${queryString}`;
          this.imageBankTab = null;
          break;
        case 'preorder':
        case 'replenishment':
          url += '/search' + (queryString ? '#filter/' + queryString : '');
          this.imageBankTab = null;
          break;
        case 'imagebank':
          url +=
            '/search' +
            searchQstr +
            (queryString ? '#filter/' + queryString : '') +
            '&imageBank=1';
      }

      if (this.selectedTab === 'preorder') {
        url += `&preorder=1${`&preorderType=${this.preorderType.toLowerCase()}`}`;
      }

      if (this.selectedTab === 'replenishment') {
        url += `&replenishment=1`;
      }

      if (this.stockweekFilter) {
        url +=
          '&stockweek=' +
          Object.keys(this.stockweekFilter)
            .map((key) => {
              return encodeURIComponent(this.stockweekFilter[key]);
            })
            .join('|');
      }
      // Set last location
      this.lastLocation = url;

      if (setLocation) {
        this.location.go(url);
      }

      return url;
    } else {
      return '';
    }
  }

  setGroupsQueryStr() {
    const groups = [];
    const groupDescriptions = [];
    let selected = this.selectedGroups;

    // Loop through the groups / subgroups and check if selected
    this.groups?.forEach((g: ArticleGroup) => {
      if (_.flatten(selected.product_group_codes).includes(g.code)) {
        groups.push(g.code);
        groupDescriptions.push(
          this.helperService.urlEncode(g.description.trimEnd())
        );

        g.sub_groups?.forEach((sg: ArticleGroup) => {
          if (_.flatten(selected.article_group_codes).includes(sg.code)) {
            groups.push(sg.code);
            groupDescriptions.push(
              this.helperService.urlEncode(sg.description.trimEnd())
            );

            sg.sub_groups?.forEach((ssg: ArticleGroup) => {
              if (
                _.flatten(selected.article_sub_group_codes).includes(ssg.code)
              ) {
                groups.push(ssg.code);
                groupDescriptions.push(
                  this.helperService.urlEncode(ssg.description.trimEnd())
                );

                // variants
                ssg.sub_groups?.forEach((vg: ArticleGroup) => {
                  if (
                    _.flatten(selected.variation_group_code).includes(vg.code)
                  ) {
                    groups.push(vg.code);
                    groupDescriptions.push(
                      this.helperService.urlEncode(vg.description.trimEnd())
                    );
                  }
                });
              }
            });
          }
        });
      }
    });

    const groupDescription = groupDescriptions
      .join(',')
      .replace('_&_', '_and_');

    if (groups.length) {
      return (
        '&groups=' +
        groups.join(',') +
        '&categories=' +
        groupDescriptions.join(',')
      );
    } else {
      return '';
    }
  }

  // Get the filters from url query string
  getFiltersFromUrl(reload, resetPage = false) {
    const filterData = new DefaultFilters();
    const url = this.url ? this.url : window.location.href;
    let urlData = '';
    let match = url.match(/(?:search|results)(?:\?|#|\/)(.*)/);
    if (match) {
      urlData = match[1];
    }
    let urlFilterData = '';
    function findIndexOfValueContainingFilter(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].includes('#filter')) {
          return i;
        }
      }
      return -1; // Return -1 if the value with '#filter' is not found in the array.
    }

    const searchFilterIndex = findIndexOfValueContainingFilter(urlData);

    if (searchFilterIndex !== -1) {
      // Merge all elements after the "search#filter" index
      let filterIndex = 0;
      const urlSegments = Object.values(urlData);
      for (let i = 0; i < urlSegments.length; i++) {
        const element = urlSegments[i];
        if (element.includes('#filter')) {
          filterIndex =
            i === urlSegments.length - 1 ? urlSegments.length - 1 : i + 1;
        }
      }
      urlFilterData = Object.values(urlData)[filterIndex];
    } else {
      console.log("Element 'search#filter' not found in the array.");
    }

    let hasSetPage = false;
    this.selectedGroups = new GroupCollection();

    // Reset searchtext when coming from details page
    if (window.location.href.indexOf('fromDetails=true') >= 0) {
      this.location.go(
        `${this.applicationService.getSelectCompanyGroupCode()}/search${window.location.hash.replace(
          '&fromDetails=true',
          ''
        )}`
      );
      this.searchText = '';
    }

    if (urlData.includes("keyword")) {
      const urlArray = urlData.split("#");
      urlData = urlArray.length > 1 ? urlArray[1] : urlArray[0];
    }

    urlData = urlData.replace("filter/", "");
    const urlFilters = urlData.split('&');

    for (let i = 0; i < urlFilters.length; i++) {
      const urlFiltersValuesData = urlFilters[i].endsWith('=')
        ? []
        : urlFilters[i].split('=');

      if (urlFiltersValuesData.length > 1) {
        const key = urlFiltersValuesData[0];
        const values = urlFiltersValuesData[1].split(',');
        switch (key.toLowerCase()) {
          case 'text':
            this.searchText = decodeURIComponent(values[0]);
            hasSetPage = true;
            break;

          case 'page':
            this.page = resetPage ? 1 : Number(values[0]);
            hasSetPage = true;
            break;

          case 'favorites':
            this.selectedTab = 'favorites';
            break;

          case 'stocklist':
            this.favoritesType = 'stocklist';
            break;

          case 'preorder':
            this.selectedTab = 'preorder';
            if (this.applicationService.isDach()) {
              this.sorting = this.searchText ? '1' : '2';
            }
            break;

          case 'replenishment':
            this.selectedTab = 'replenishment';
            break;

          case 'imagebank':
            this.selectedTab = 'imagebank';
            break;

          case 'brands':
            const brands = decodeURIComponent(values[0]).split(',');
            console.log(brands);
            this.searchService.brands = brands;
            break;

          case 'article_type':
            this.searchService.articleType = values[0];
            break;

          case 'pagesize':
            this.pageSize = Number(values[0]);
            break;

          case 'leaserequest':
            this.leaseRequest = true;
            break;

          case 'stockweek':
            const stockweekFilter = decodeURI(values[0]).split('|');
            this.stockweekFilter = new StockweekFilter();
            this.stockweekFilter.from_week = Number(stockweekFilter[0]);
            this.stockweekFilter.from_year = Number(stockweekFilter[1]);
            this.stockweekFilter.to_week = Number(stockweekFilter[2]);
            this.stockweekFilter.to_year = Number(stockweekFilter[3]);
            break;

          case 'groups':
            this.selectedTab = 'search';
            this.setGroup(
              { product_group_codes: decodeURI(values[0]) },
              'product_group_codes'
            );
            if (values.length > 1) {
              this.setGroup(
                { article_group_codes: decodeURI(values[1]) },
                'article_group_codes'
              );
            }
            if (values.length > 2) {
              this.setGroup(
                { article_sub_group_codes: decodeURI(values[2]) },
                'article_sub_group_codes'
              );
            }
            if (values.length > 3) {
              this.setGroup(
                { variation_group_code: decodeURI(values[3]) },
                'variation_group_code'
              );
            }
            break;

          case 'price':
            filterData['PRICE'] = {
              options: [],
            };
            filterData['PRICE']['options'].push({
              id: 'PRC_MIN',
              value: decodeURI(values[0]),
            });

            filterData['PRICE']['options'].push({
              id: 'PRC_MAX',
              value: decodeURI(values[1]),
            });
            break;

          default:
            for (let j = 0; j < values.length; j++) {
              const selectedFilter = {};
              const filterKey = decodeURIComponent(values[j]);
              selectedFilter[filterKey] = true;
              const formattedKey = key;
              if (!filterData[formattedKey]) {
                filterData[formattedKey] = [];
              }

              filterData[formattedKey][filterKey] = true;
            }
        }
      }
    }

    if (
      !hasSetPage &&
      !window.location.href.includes('page=') &&
      !window.location.href.includes('favorites') &&
      !this.url
    ) {
      this.page = 1;
    }

    // Reset selected filters
    if (this.sideBar) {
      let sideBarFilters = { ...this.sideBar.selectedFilters };
      for (let filter in sideBarFilters) {
        sideBarFilters[filter] = [];
      }

      // Show selected filters in sidebar
      sideBarFilters = Object.assign(sideBarFilters, filterData);
      this.sideBar.selectedFilters = sideBarFilters;
      this.sideBar.getPromotions();
    }

    // Set selected filters
    this.setFilters(filterData);

    if (reload) {
      this.getResults(false, 1);
    }
  }

  getLastScrollPos() {
    this.lastScrollPos =
      document.documentElement.scrollTop === 0
        ? document.body.scrollTop
        : document.documentElement.scrollTop;
  }

  showArticleDetails(article, index, showBackButton = true) {
    const url = `/${this.companyGroupCode}/articleDetails/${article.id}${
      article.single_article_code ? '/' + article.single_article_code : ''
    }?fromSearch`;
    if (this.url) {
      this.router.navigateByUrl(url);
    } else {
      if (this.searchVisible) {
        this.getLastScrollPos();
      }
      this.selectedArticle = article;
      this.searchVisible = false;
      this.changeDetector.detectChanges();
      this.location.go(url);

      if (article && this.articleDetailsComponent) {
        this.articleDetailsComponent.modelId = article.id;
        this.articleDetailsComponent.selectedArticle =
          article.single_article_code;
        this.articleDetailsComponent.article = article;
        this.articleDetailsComponent.fromSearch = showBackButton;
        this.articleDetailsComponent.leaseRequest = true;

        this.articleDetailsComponent.showDetailed = true;
        this.selectedIndex = _.findIndex(this.articles, { id: article.id });
        if (index || index === 0) {
          this.trackingService.articleClick(article, index);
        }
        this.articleDetailsComponent.showInstant();
      }
    }
  }

  navigationChange(direction) {
    let nextArticle: Article = null;
    if (direction === 'prev' && this.selectedIndex === 0) {
      if (this.page === 1) {
        return false;
      } else {
        this.page--;
        this.searchVisible = false;
        this.getResults(false, 1).then(() => {
          nextArticle = this.articles[this.articles.length - 1];
          this.setArticleDetailsUrl(nextArticle);
          this.showArticleDetails(nextArticle, null);
        });
      }
    } else if (
      direction === 'next' &&
      this.selectedIndex === this.articles.length - 1
    ) {
      if (this.page * this.pageSize >= this.count) {
        return false;
      } else {
        this.page++;
        this.searchVisible = false;
        this.setUrl(this.sideBar.selectedFilters, false);
        this.getResults(false, 1).then(() => {
          nextArticle = this.articles[0];
          this.setArticleDetailsUrl(nextArticle);
          this.showArticleDetails(nextArticle, null);
        });
      }
    } else {
      nextArticle =
        this.articles[this.selectedIndex + (direction === 'next' ? 1 : -1)];
      this.setArticleDetailsUrl(nextArticle);
      this.showArticleDetails(nextArticle, null);
    }
  }

  private setArticleDetailsUrl(article: Article) {
    if (article) {
      this.location.go(
        `${this.applicationService.getSelectCompanyGroupCode()}/articleDetails/${
          article.id
        }` + (this.leaseRequest ? 'fromLeaseRequest=X' : '')
      );
    }
  }

  countSelection() {
    this.selectedForBasketCount =
      this.articleCollectorService.selectedForBasketCount;
    this.showAddAllButton = this.selectedForBasketCount > 0;

    if (this.selectedForBasketCount === 0) {
      this.articles.forEach((article: Article) => {
        article.selected = false;
      });
    }
  }

  addToSelection(selectedArticle) {
    this.selectedForBasketCount =
      this.articleCollectorService.countSelected(selectedArticle);
    this.showAddAllButton = this.selectedForBasketCount > 0;
  }

  // Add multiple selection to basket
  addAll() {
    const selectedArticles = [];
    const selectedForBasket = this.articleCollectorService.selectedForBasket;

    Object.keys(selectedForBasket).forEach(function (key) {
      // Make a copy - no reference
      const article = Object.assign({}, selectedForBasket[key]);
      selectedArticles.push(article);
    });
    const modalRef = this.modalService.open(AddAllModalComponent, {
      size: 'lg',
      container: '#modalContainer',
    });
    modalRef.componentInstance.articles = selectedArticles;
  }

  deselectAll() {
    this.articleCollectorService.deselectAll();
  }

  showCompared() {
    const modalRef = this.modalService.open(CompareArticlesComponent, {
      size: 'lg',
      container: '#modalContainer',
    });
  }

  compareAddAll() {
    this.articleCollectorService.compareAddAll();
    this.showAddAllButton = false;
  }

  clearAddAll() {
    this.articleCollectorService.deselectAll();
    this.showAddAllButton = false;
  }

  articleId(index, article) {
    return article !== null ? article.id : index;
  }

  setTab(selectedTab) {
    let url = `/${this.companyGroupCode}/search/${this.searchText}`;
    this.count = null;
    switch (selectedTab) {
      case 'favorites':
      case 'imagebank':
      case 'preorder':
      case 'replenishment':
        url += `#filter/&${selectedTab}=1`;
        this.articles = [];
        break;
    }

    this.page = 1;

    if (selectedTab === 'preorder' && this.applicationService.isDach()) {
      this.sorting = '2';
    } else {
      this.sorting = '1';
    }

    if (selectedTab !== 'imagebank') {
      this.imageBankTab = null;
    }

    if (this.selectedTab === 'preorder') {
      this.preorderVisited = true;
    }

    if (this.selectedTab === 'replenishment' && !this.stockLoaded) {
      this.replenishmentService.getCurrentStock();
    }

    this.selectedTab = selectedTab;
    this.lastLocation = url;
    this.location.go(url);
    this.loadResults();
  }

  saveReplenishment() {
    const invalidInputs = document.getElementsByClassName(
      'replenishment-invalid'
    );
    if (invalidInputs.length) {
      this.alertService.showPopup(
        this.translateService.instant('REQUIRED_FIELDS_TEXT'),
        'error',
        0
      );
    } else {
      this.replenishmentService.updateStock(this.articles);
      this.replenishmentNotSaved = false;
    }
  }

  // Filter with text from sidebar
  filterWithKeyword(data: RefineKeyword) {
    this.searchText = data.keyword;
    this.filterSearchText = data.keyword;
    if (!data.keepFilters) {
      this.selectedGroups = new GroupCollection();
      this.setUrl({}, true);
    }
    this.getFiltersFromUrl(true);
  }

  preorderTypeChanged(type: string) {
    this.selectedGroups = new GroupCollection();
    this.page = 1;
    this.preorderType = type ? type : 'Bicycles';
    this.articles = [];
    this.filters = {};
    for (let filter in this.sideBar.selectedFilters) {
      this.sideBar.selectedFilters[filter] = [];
    }
    this.setUrl({}, true);
    this.getResults(true, 0);
  }

  showReplenishmentSelection() {
    const modal = this.modalService.open(ReplenishmentSelectionModalComponent, {
      centered: true,
      size: 'lg',
      container: '#modalContainer',
    });
    modal.componentInstance.readonly = !this.showSaveReplenishment;
  }

  resetFilters(sidebar: SideBarComponent) {
    this.selectedGroups = new GroupCollection();
    sidebar.removeFilters(true);
  }
}
