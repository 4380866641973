import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerPortalLogoComponent } from 'src/app/components/partner-portal/partner-portal-logo/partner-portal-logo.component';
import { RouterModule } from '@angular/router';
import { PartnerStoreFooterComponent } from 'src/app/components/partner-store/partner-store-footer/partner-store-footer.component';
import { ResetPasswordComponent } from 'src/app/components/login-components/reset-password/reset-password.component';


@NgModule({
  declarations: [
    PartnerPortalLogoComponent,
    PartnerStoreFooterComponent,
  ],
  imports: [
    RouterModule, CommonModule
  ],
  exports: [
    PartnerPortalLogoComponent,
    PartnerStoreFooterComponent,
  ]
})
export class PartnerPortalSharedModule { }
