import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Article } from "src/app/interfaces/article";
import { ArticleMatrix } from "src/app/interfaces/article-matrix";
import { ApplicationService } from "src/app/services/application.service";
import { ArticleService } from "src/app/services/article.service";
import * as _ from "underscore";

@Component({
  selector: "app-matrix",
  templateUrl: "./matrix.component.html",
  styleUrls: ["./matrix.component.scss"]
})
export class MatrixComponent implements OnInit {
  @Input() article: Article;
  @Output() articleSelected: EventEmitter<any> = new EventEmitter<any>();

  // DELIVERYNOTICE2022
  deliveryDateNotice2022: string = ''
  companyGroupCode: string = ""
  modelTemporarilyUndeliverable: boolean;
  expand: boolean;

  constructor(private cdRef:ChangeDetectorRef, private translateService: TranslateService, private applicationService: ApplicationService, private articleService: ArticleService) {}

  ngOnInit() {
    this.cdRef.detectChanges();

    this.getTempUndeliverable();

    // DELIVERYNOTICE2022
    this.deliveryDateNotice2022 = this.translateService.instant("DELIVERY_DATE_NOTICE_2022")
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
  }

  getTempUndeliverable() {
    if (this.article.matrix.options && this.article.matrix.options.length) {
     this.modelTemporarilyUndeliverable = this.articleService.getTempUndeliverable(this.article.matrix, this.article.single_article_code)
    }
  }

  selectArticle(articleRow) {
    articleRow.promotion = false;
    this.article = Object.assign(this.article, articleRow);

    this.article.promotion_price = articleRow.promotion_price;
    this.articleSelected.next(this.article);

    // Set the visible price to the selected article in the matrix
    if (this.article.nett_prices) {
      const newNettPrice = _.findWhere(this.article.nett_prices, {
        single_article_code: this.article.single_article_code
      });
      if (newNettPrice) {
        this.article.net_price = newNettPrice.net_price;
        this.article.has_different_prices = false;
      }
    }
  }

  // DELIVERYNOTICE2022
  showDeliveryDateNotice2022(article) {
    if (article.stock_year >= 2022 && article.stock_year < 9999 && (this.companyGroupCode === 'WG' || this.companyGroupCode === 'GHO') && article.article_type && article.article_type.startsWith("F")) {
      return this.deliveryDateNotice2022
    }
  }
}
