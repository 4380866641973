import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  SimpleChanges,
  EventEmitter,
  Output,
} from "@angular/core";
import { CmsService } from "src/app/services/cms.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { CompanyPage } from "src/app/interfaces/cms/company-page";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { timer } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { HelperService } from "src/app/services/helper.service";
import { StoryblokService } from "src/app/services/storyblok.service";
import { StoryblokComponents } from "../../storyblok/storyblok-components";

@Component({
  selector: "app-cms-page-content",
  templateUrl: "./cms-page-content.component.html",
  styleUrls: ["./cms-page-content.component.scss"],
})
export class CmsPageContentComponent implements OnInit {
  @Input() pageId: string;
  @Input() contentId: string;
  @Input() showBreadCrumbs: boolean;
  @Input() showNotFound: boolean = true;
  @Output() contentLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("Content") private Content: ElementRef;
  story = { content: null, name: "" };
  components = StoryblokComponents;
  breadcrumbs: Breadcrumb[];
  htmlContent: string;
  id: string;
  title: string;
  loading = false;
  constructor(
    private cmsService: CmsService,
    private helperService: HelperService,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private elem: ElementRef,
    private storyblokService: StoryblokService
  ) {}

  ngOnInit() {
    this.id = this.contentId ? this.contentId : this.pageId;
    if (this.pageId) {
      //  this.getContent(this.pageId);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["pageId"]) {
      this.getContent(changes["pageId"]["currentValue"]);
    }
  }

  getContent(pageId: string, delay: number = 0) {
    if (pageId) {
      if (this.showNotFound) {
        this.applicationService.showLoader(true);
      }

      this.id = pageId;
      if (this.cmsService.cachedContent[pageId]) {
        const htmlContent = this.cmsService.cachedContent[pageId]["content"];
        this.title = this.cmsService.cachedContent[pageId]["title"];
        this._setHtmlContent(htmlContent);
        this.contentLoaded.emit(true);
        if (this.showBreadCrumbs) {
          this.setBreadcrumbs(this.title);
        }
        this.applicationService.hideLoader();
      } else {
        timer(delay).subscribe(() => {
          this.cmsService
            .getPage(pageId)
            .subscribe((apiResponse: ApiResponse) => {
              this.applicationService.hideLoader();
              if (this.helperService.checkResponse(apiResponse)) {
                const cmsItem = apiResponse.result as CompanyPage;
                this.title = cmsItem.language_definitions[0].title;

                try {
                  const story = JSON.parse(
                    cmsItem.language_definitions[0].content
                  );
                  if (typeof story.content !== "undefined") {
                    this.story = story;
                  } else {
                    // render plain HTML (old CMS layout)
                    this._setHtmlContent(story);
                  }
                } catch (err) {
                  this._setHtmlContent(cmsItem.language_definitions[0].content);
                }

                if (this.showBreadCrumbs) {
                  this.setBreadcrumbs(this.title);
                }
              } else if (this.showNotFound) {
                this._setHtmlContent(
                  `<h2 class="mt-5">${this.translateService.instant(
                    "NOT_FOUND"
                  )}</h2>`
                );
              }
              this.contentLoaded.emit(true);
            });
        });
      }
    } else {
      this.title = "";
      this.htmlContent = "";
      this.loading = false;
      this.applicationService.hideLoader();
    }
  }

  _setHtmlContent(content: string) {
    const elements = this.elem.nativeElement.getElementsByClassName(`content`);
    if (elements && elements.length) {
      elements[0].innerHTML = this.storyblokService.formatContent(content);
    }
  }

  setBreadcrumbs(title: string) {
    // Set breadcrumbs
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(
        title,
        this.applicationService.getSelectCompanyGroupCode() + "#"
      ),
    ];
  }
}
