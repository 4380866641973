export interface PersonalAddress {
  first_name: string;
  last_name: string;
  address_line_1: string;
  address_line_2: string;
  zip_code: string;
  city: string;
  country_code: string;
  available_country_codes: string;
  shipping_address_line_1: string;
  shipping_address_line_2: string;
  shipping_zip_code: string;
  shipping_city: string;
  shipping_country_code: string;
  is_shipping_address_different: boolean;
}

export class PersonalAddress implements PersonalAddress {

}
