import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { SearchService } from 'src/app/services/search.service';
import { Observable, of, Subject, timer } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
  switchMap,
} from 'rxjs/operators';
import { NgbTypeahead, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';
import { fromEvent } from 'rxjs';
import { InstantResultComponent } from '../instant-result/instant-result.component';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'src/app/interfaces/article';
import { SuggestTermsComponent } from '../../search-components/suggest-terms/suggest-terms.component';
import { ArticleBomComponent } from '../../article-components/article-bom-components/article-bom.component';
import { AccountService } from 'src/app/services/account.service';
import { TrackingService } from 'src/app/services/tracking.service';
import * as moment from 'moment';
import { ConfirmDialogComponent } from '../../modal-components/confirm-dialog/confirm-dialog.component';
import { SaveFiltersComponent } from '../../search-components/side-bar/save-filters/save-filters.component';
import { SearchType } from 'src/app/interfaces/tracking/searchAction';
import { MenuLayout } from 'src/app/interfaces/menu-layout';
import { ArticleGroup } from 'src/app/interfaces/articleGroup';
import { MenuItem } from 'src/app/interfaces/menuItem';
import { GroupCollection } from 'src/app/interfaces/group-collection';
import { GroupsTreeSearchbarComponent } from '../../search-components/groups-tree-searchbar/groups-tree-searchbar.component';
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Output() searchClicked: EventEmitter<any> = new EventEmitter<any>();
  searching = false;
  searchFailed = false;
  searchText = '';
  model = '';
  selectedCompanyGroup = '';
  showroomMode: boolean;
  accessibilityMode: boolean;
  grossPrice: boolean;
  focus: boolean;
  alphabet = 'abcdefghijklmnopqrstuvwxz';
  resultCount = 0;
  placeholder = 'assets/images/bicycle-solid.svg';
  foccused = new Subject<string>();
  showSuggestions = true;
  showAlternativeSuggestions = false;
  instantArticle: Article;
  showShowRoomMode = true;
  searchDisabled = false;
  @ViewChild('elem') elem: ElementRef;
  @ViewChild('instance') instance: NgbTypeahead;
  @Output() showArticleDetails: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchInput', { read: ElementRef }) searchInput: any;
  @ViewChild('instantResult') instantresult: InstantResultComponent;
  @ViewChild(SuggestTermsComponent) suggestTerms: SuggestTermsComponent;
  showBom: boolean;
  currentWeekNumber: number;
  currentDate: string;
  showWeekNumber = true;
  itemSelectMode: boolean;
  selectedGroup: any;
  groups: MenuItem[] = [];
  treeGroupSelected: boolean;
  showTreeView: boolean;

  get selectedProductGroupDescription() {

    if (this.selectedGroup?.variation_group_code) {
      return this.selectedGroup?.variation_group_code.split('^')[1];
    }

    if (this.selectedGroup?.article_sub_group_codes) {
      return this.selectedGroup?.article_sub_group_codes.split('^')[1];
    }

    if (this.selectedGroup?.article_group_codes) {
      return this.selectedGroup?.article_group_codes.split('^')[1];
    }

    if (this.selectedGroup?.product_group_codes) {
      return this.selectedGroup?.product_group_codes.split('^')[1];
    }

    return this.translateService.instant("ALL_GROUPS");
  }

  constructor(
    public searchService: SearchService,
    private accountService: AccountService,
    private router: Router,
    private modalService: NgbModal,
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    private trackingService: TrackingService,
    private route: ActivatedRoute,
  ) {
    router.events.subscribe((navigationData) => {
      //this.selectedGroup = "";
      if (
        navigationData['url'] &&
        navigationData['url'].indexOf(this.searchText) < 0
      ) {
        this.searchText = '';
        this.model = '';
      }
    });

  }

  ngOnInit() {
    this.getGroups();
    this.currentWeekNumber = moment().isoWeek();
    this.currentDate = moment().format("DD-MM-YYYY");
    this.showWeekNumber = !this.applicationService.isPartnerPortal();

    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
    this.grossPrice = this.applicationService.getSetting(
      'showGrossPrice',
      this.grossPrice
    );

    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(() => {
      this.searchText = '';
      this.getGroups();
    });

    this.getShowShowRoomMode();
    this.handleNavigation();

    this.searchService.searchTextEmitted$.subscribe((searchText) => {
      setTimeout(() => {
        this.model = searchText;
      }, 0);
    });

    this.route.queryParams.subscribe((params) => {
      if (params['keyword']) {
        this.model = params['keyword'];
      }
    })

    this.searchService.groupsEmitted$.subscribe((searchText) => {
      this.searchText = '';
    });
  }

  getGroups() {
    this.applicationService.getMenuItems(false).then((data: MenuLayout) => {
      this.groups = data.browse_groups;
    })
  }

  getShowShowRoomMode() {
    this.showShowRoomMode = this.applicationService.getSetting(
      'showShowroomMode',
      true
    );

    this.showBom =
      this.accountService.getAccountType() !== 'DLR' ||
      this.applicationService.getSelectCompanyGroupCode() !== 'WG';
  }

  handleNavigation() {
    const keydown = fromEvent(document.body, 'keydown').subscribe((e) => {
      if (e) {
        const code = String(e['code']).toLowerCase().replace('key', '');
        switch (code) {
          case 'f6':
            const modalRef = this.modalService.open(ConfirmDialogComponent, {
              size: 'lg',
              windowClass: 'medium',
              container: '#modalContainer',
            });

            const type = 'load';
            modalRef.componentInstance.title =
              this.translateService.instant('LOAD_FILTER');
            modalRef.componentInstance.setContent(
              SaveFiltersComponent,
              'type',
              type
            );
            break;
          case 'f4':
            this.searchInput.nativeElement.focus();
            break;
          case 'f3':
            this.router.navigateByUrl(
              `/${this.applicationService.getSelectCompanyGroupCode()}/search`
            );
            break;
          case 'f2':
            this.router.navigateByUrl(
              `/${this.applicationService.getSelectCompanyGroupCode()}/dealeroverview`
            );
            break;
          case 'f1':
            this.router.navigateByUrl(
              `/${this.applicationService.getSelectCompanyGroupCode()}/`
            );
            break;
        }
      }
    });
  }

  public openTypeahead(): void {
    this.itemSelectMode = false;
    if (this.elem.nativeElement) {
      // Dispatch event on input element that NgbTypeahead is bound to
      this.elem.nativeElement.dispatchEvent(new Event('input'));
      // Ensure input has focus so the user can start typing
      this.elem.nativeElement.focus();
    }
  }

  private blurInput() {
    const searchInput = document.getElementById('typeahead-http');
    if (searchInput) {
      searchInput.blur();
    }
  }

  getResults(searchText = '') {
    if (!searchText) {
      const inputEl = this.searchInput.nativeElement;
      this.searchText = inputEl.value;
    } else {
      this.searchText = searchText;
    }
    if (
      this.searchText === 'ab-test-b' ||
      this.searchText === 'ab-test-c' ||
      this.searchText === 'ab-test-stop'
    ) {
      localStorage.setItem('ab-test', this.searchText);
      window.location.href = `/${this.applicationService.getSelectCompanyGroupCode()}`;
      return false;
    }
    this.trackingService.searchBar('Search – all', this.searchText);
    this.searchDisabled = true;
    setTimeout(() => {
      this.searchDisabled = false;
    }, 600);

    this.searchClicked.emit(this.searchText);
    // const groupData = this.getGroupData();
    // this.searchService.emitFilterChange({
    //   keyword: this.searchText,
    //   group: groupData[1]
    // });
    this.searchService.saveToRecentSearched(this.searchText);
    this.gotoSearchPage();
    this.showSuggestions = false;
    this.blurInput();
  }

  groupSelected(data) {
    this.selectedGroup = data;
    this.treeGroupSelected = true;
    this.showTreeView = false;
    timer(300).subscribe(() => {
      this.treeGroupSelected = false;
    })
  }

  private getGroupData() {
    // const groupData = this.selectedGroup.split('^');
    // return groupData;
  }

  gotoSearchPage(track = true) {
    const isPreorder = window.location.href.indexOf('preorder') >= 0;
    const isReplishment = window.location.href.indexOf('replenishment') >= 0;
    let groupFilter = '';

    if (this.selectedGroup && this.selectedGroup.product_group_codes) {


      groupFilter = `&groups=${this.selectedGroup.product_group_codes?.split("^")[0] + (this.selectedGroup.article_group_codes ? ',' + this.selectedGroup.article_group_codes.split("^")[0] : '') + (this.selectedGroup.article_sub_group_codes ? ',' + this.selectedGroup.article_sub_group_codes.split("^")[0] : '') + (this.selectedGroup.variation_group_code ? ',' + this.selectedGroup.variation_group_code.split("^")[0] : '')}&groupFiltered=true`;
    }

    let url =
      this.applicationService.getSelectCompanyGroupCode() +
      '/search' +
      (this.searchText
        ? '?keyword=' + encodeURIComponent(this.searchText)
        : '') +
      '#filter/&pageSize=' +
      this.applicationService.getSelectedPageSize('24') +
      (isPreorder ? '&preorder=1' : '') +
      (isReplishment ? '&replenishment=1' : '') +
      (groupFilter)

    if (window.location.href.includes('imagebank')) {
      url += '&imagebank=1';
    }

    this.blurInput();
    if (track) {
      this.trackingService.searchAction(this.searchText, SearchType.query, 0);
    }

    this.router.navigateByUrl("/RefreshComponent", { skipLocationChange: true })
      .then(() => {
        this.router.navigateByUrl(url);
      });
  }

  getRecent(searchText) {
    this.trackingService.searchAction(searchText, SearchType.recent, 0);
    this.searchClicked.emit(searchText);
    this.searchService.emitChange(searchText);
    this.searchText = searchText;

    this.gotoSearchPage(false);
  }

  gotoItem(article) {
    if (this.itemSelectMode) {
      this.trackingService.searchAction(
        this.model,
        SearchType.product,
        0,
        article.id,
        article.description,
        article.brand
      );
      //this.trackingService.searchBar("Search suggest – item", this.model);
      this.searchText = '';
      this.router.navigateByUrl(
        this.applicationService.getSelectCompanyGroupCode() +
        '/articleDetails/' +
        article.id +
        (article.single_article_code ? `/${article.single_article_code}` : '')
      );
      this.searchService.showDetails(article);
      this.blurInput();
      return false;
    } else {
      this.searchText = this.model;
      this.gotoSearchPage();
    }
  }

  onSelect($event) {
    if (!$event.item.id) {
      this.getResults();
    } else {
      this.focus = false;
      if (typeof $event.item.article_type !== 'undefined') {
        this.gotoItem($event.item);
      } else {
        this.gotoGroup($event.item);
      }
    }

    $event.preventDefault();
    return false;
  }

  setAccessibility(value) {
    if (value.srcElement.checked) {
      document.body.className = 'accMode';
    } else {
      document.body.className = '';
    }
  }

  setShowroomMode(value) {
    this.applicationService.saveShowroomMode(value.srcElement.checked);
  }

  setGrossPrice(value) {
    this.applicationService.saveShowGrossPrice(value.srcElement.checked);
  }

  showResult() {
    this.focus = true;
    this.showSuggestions = true;
    if (this.resultCount === 1) {
      this.instantresult.display = true;
    } else if (
      this.resultCount === 0 &&
      this.model.length > 2 &&
      !this.instantresult.instantArticle
    ) {
      this.showAlternativeSuggestions = true;
    } else {
      this.instantresult.display = false;
    }
  }

  detectChange(event) {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      this.itemSelectMode = true;
    } else {
      this.itemSelectMode = false;
    }
    this.showSuggestions = event.key !== 'Enter';
    this.instantresult.display = false;
    this.instantArticle = new Article();
    event.preventDefault();
    return false;
  }

  resetSearch(input, event) {
    const self = this;
    setTimeout(function () {
      self.model = '';
      self.searchText = '';
      input.value = '';
      self.searchInput.value = '';
      self.showSuggestions = false;
      self.showAlternativeSuggestions = false;
      self.getResults();
    }, 0);

    event.preventDefault();
    return false;
  }

  gotoBom($event, article) {
    const modalRef = this.modalService.open(ArticleBomComponent, {
      centered: true,
      size: 'lg',
      container: '#modalContainer',
    });
    modalRef.componentInstance.article = article;
    this.blurInput();
    event.preventDefault();
    return false;
  }

  showAll(event) {
    this.blurInput();
  }

  // Get alternative suggest when f.e. a typo is made
  getAlternativeSuggestions(term = '') {
    if (term) {
      this.suggestTerms.searchTerm = term;
    }

    this.showAlternativeSuggestions = true;
    this.suggestTerms.getSuggestions();
    this.suggestTerms.showTerms = true;
  }

  gotoSuggestion(searchText: string) {
    this.trackingService.searchAction(searchText, SearchType.corrected, 0);
    this.getResults(searchText);
  }
  scrollToggle() {
    if (window.innerHeight < 575 && window.innerWidth < 400) {
      let prevScrollpos = window.pageYOffset;
      window.onscroll = function () {
        const currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos || currentScrollPos <= 0) {
          document.getElementById('searchbar').style.display = 'inline-block';
        } else {
          document.getElementById('searchbar').style.display = 'none';
        }
        prevScrollpos = currentScrollPos;
      };
    }
  }

  showRecent(recent: any) {
    if (this.model === '') {
      recent.getKeyWords(true);
      recent.getRecentArticles(true);
    }
  }

  gotoGroup(group) {
    if (this.itemSelectMode) {
      //this.trackingService.searchBar("Search suggest – category", this.model);
      const groupFormatted = String(group.description).replace(/_/g, ',');
      const groupUrlFormatted = String(group.id).replace(/_/g, ',');
      this.trackingService.searchAction(
        this.model,
        SearchType.category,
        0,
        undefined,
        undefined,
        undefined,
        undefined,
        groupFormatted
      );
      this.router.navigateByUrl(
        `${this.applicationService.getSelectCompanyGroupCode()}/search#filter/COMMON=&groups=${groupUrlFormatted}`
      );
      setTimeout(() => {
        this.searchService.setGroups('');
      }, 0);
    } else {
      this.searchText = this.model;
      this.gotoSearchPage();
    }
  }

  scrollToGroup(element: GroupsTreeSearchbarComponent) {
    element.setDropdown();
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.resultCount = 0;
        this.searching = true;
        this.instantArticle = null;
        this.instantresult.display = false;
        this.showAlternativeSuggestions = false;
      }),
      switchMap((term) =>
        term.length > 2 && this.showSuggestions
          ? this.searchService.searchSuggestions(term).pipe(
            tap((data) => {
              this.resultCount = data.length;
              this.searchFailed = false;

              // Check if there is a single instant result
              if (data.length === 1) {
                this.searchFailed = true;
                this.instantArticle = data[0];
                this.instantresult.display = true;
                data = [];
                return of([]);
              }

              // Add index to each group
              let count = 0;
              data.forEach((r) => {
                if (!r.model_code && r.id) {
                  r.index = count;
                  count++;
                }
              });

              if (data.length === 0) {
                this.instantresult.getResult();
                this.getAlternativeSuggestions(term);
              }

              this.searchService.getNettPrices(
                data.filter((r) => {
                  return typeof r.model_code !== 'undefined';
                })
              );

              if (data.length >= 5) {
                data.push({
                  description: this.translateService.instant('VIEW_ALL'),
                });
              }
            }),
            catchError(() => {
              this.searchFailed = true;

              // Check if there is a single instant result
              this.instantresult.getResult();
              return of([]);
            })
          )
          : of([])
      ),
      tap(() => (this.searching = false))
    );

  formatter = (x: { description: string }) => x.description;
}
