<input *ngIf="showDetailed && specifications" type="search" class="form-control mt-2" [placeholder]="'KEYWORD'|translate" [(ngModel)]="keyword" />
<h4 *ngIf="!showDetailed && specifications" class="mb-3">{{"SPECIFICATIONS"|translate}}</h4>

<div
  class="card-columns gap-2"
  *ngIf="specifications"
  [style.column-count]="
    showDetailed && !kioskMode && specifications.length > 1 ? 3 : 1
  "
  [style.column-count]="kioskMode ? 2 : ''"
  [ngClass]="[!showDetailed && specifications.length > 2 ? 'compact' : '','groups-' + specifications.length]"
>
  <div
    class="border-0 card bg-transparent card-pin"
    [ngClass]="!showDetailed ? 'pt-3 pb-1' : ''"
    *ngFor="let specGroup of specifications"
  >
    <div
      class="card-body px-0"
      [ngClass]="[
        specGroup.expanded ? 'expanded' : '',
        !showDetailed && !specGroup.expanded ? 'p-0' : ''
      ]"
    >
      <h5
        class="card-title"
        (click)="specGroup.expanded = specGroup.expanded ? false : true"
      >
        {{
          specGroup.description ? specGroup.description : ("OTHER" | translate)
        }}
      </h5>
      <ul
        class="ps-0 list-group list-group-flush"
        [ngClass]="'groups-' + specifications.length"
        *ngIf="specGroup && specGroup.items"
      >
        <ng-container *ngIf="!kioskMode">
          <li
            *ngFor="let spec of specGroup.items"
            class="ps-0 py-0 list-group-item bg-transparent"
          >
            <ng-container
              *ngIf="
                spec.code == 'DELIVERY_CONTENTS' && spec.value.length >= 35;
                else restOfContent
              "
              ><span [innerHTML]="spec.description + ':'"></span>
              <ul>
                <ng-container
                  *ngFor="
                    let item of splitStringIntoArrayByCommaOrStar(spec.value)
                  "
                >
                  <li *ngIf="!keyword || matchesKeyword(item)" [innerHTML]="item"></li>
                </ng-container>
              </ul>
            </ng-container>
            <ng-template #restOfContent>
              <span
                class="d-flex py-2 flex-column"
                *ngIf="!keyword || matchesKeyword(spec.description + ': ' + spec.value)"
                [@inOutAnimation]
                ><span class="spec-label" [innerHTML]="spec.description + ':&nbsp;'"></span><span class="spec-value" [innerHTML]="spec.value"></span>
            </span>
            </ng-template>
          </li>
        </ng-container>
        <ng-container *ngIf="kioskMode">
          <li *ngFor="let spec of specGroup.items" class="ps-0 list-group-item">
            <span
              class="spec-description"
              [innerHTML]="spec.description + ':'"
            ></span>
            <span class="spec-value" [innerHTML]="spec.value"></span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
<app-disclaimer
  [companyGroups]="['WG', 'GHO']"
  [companyIds]="[101, 25]"
  [companyId]="companyId"
  [text]="'DISCLAIMER_SPECS' | translate"
  [class]="'d-block mx-3 px-1'"
></app-disclaimer>
