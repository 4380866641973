import { Article } from "../../article";

export interface OrderLine {
  accentry_id: string;
  article_code: string;
  article_description: string;
  article_type: number;
  article_type_text: string;
  backward_shifting_status: number;
  backward_shifting_weeks: string;
  can_push_fordward_delivery_week: boolean;
  company_id: string;
  consumer_price: string;
  delivery_week_or_date: string;
  old_delivery_week: string;
  desired_delivery_week: string;
  ean_code: string;
  erp_id: string;
  line_id: string;
  match_code: string;
  netto_price: string;
  order_reference: string;
  quantity: number;
  quantity_delivered: number;
  quantity_in_transit: number;
  quantity_to_send: number;
  remark: string;
  shipping_address_line_1: string;
  shipping_address_line_2: string;
  shipping_address_line_3: string;
  shipping_address_line_4: string;
  shipping_address_line_5: string;
  shipping_code: string;
  shipping_id: string;
  sold: string;
  status: string;
  image: string;
  article: Article;
  parsed_delivery_week_range: string;
  battery: string;
}

export class OrderLine implements OrderLine {
  total_in_transit: number;
  total_quantity: number;
  address_type: string;
}
