import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
} from '@angular/router';
import { Component, HostListener, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from './services/application.service';
import { ChatService } from './services/chat.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ApiResponse } from './interfaces/api-response';
import { AccountService } from './services/account.service';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFormatter } from './ngbFormatter';
import { Observable, timer, Subscription } from 'rxjs';
import { AuthenticateService } from './services/authenticate.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TrackingService } from './services/tracking.service';
import { environment } from './../environments/environment';
import { YearAgreementService } from './services/year-agreement.service';
import { Dealer } from './interfaces/dealer';
import { ShowDealerAgreementsComponent } from './components/year-agreement/bikes/show-dealer-agreements/show-dealer-agreements.component';
import { AccountAgreement } from './interfaces/yearAgreement/account-agreement';
import { Slideshow } from './interfaces/cms/slideshow';
import { SlideshowService } from './services/cms/slideshow.service';
import { SlideshowModalComponent } from './components/cms/slideshow-modal/slideshow-modal.component';
import { AlertService } from './services/alert.service';
import { Location } from '@angular/common';
import { KioskService } from './services/kiosk.service';
import { BasketPreviewComponent } from './components/basket-components/basket-preview/basket-preview.component';
import { CacheService } from './services/cache.service';
import { CacheType } from './interfaces/cache-tracker';
import { StoryblokService } from './services/storyblok.service';
import { YearAgreementType } from './interfaces/year-agreement-type';
import { YearAgreementBaseService } from './services/year-agreements/year-agreement-base.service';
import moment from 'moment';
import { NdaCheckComponent } from './components/preorder-components/nda-check/nda-check.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PrimeNGConfig } from 'primeng/api';
import { SettingsService } from './services/settings.service';
import { SignedCondition } from "./interfaces/signed-condition";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFormatter },
    NgbModalConfig,
  ],
})
export class AppComponent {
  @ViewChild('basketPreview') basketPreview: BasketPreviewComponent;
  isLoggedIn$: Observable<boolean>;
  initScreenSaver = true;
  screenSaverActive = false;
  selectedCompanyGroup = 'ANL';
  showMainInterface: boolean;
  showBase: boolean;
  loaderColor = '#35b552';
  accountType = 'DLR';
  isDealer: boolean;
  isHelpdesk: boolean;
  showChatButton: boolean;
  showSpinner = false;
  showroomMode: any;
  selectedDealerInformation: Dealer;
  agreementInterval: any;
  slideshowInterval: any;
  slideshows: Slideshow[];
  showNav: boolean;
  timer: Subscription;
  kioskMode: boolean = false;
  kioskCheckerActive: boolean;
  environmentName: string;
  showPreorderBasket: boolean;
  slideShowsChecked: boolean;
  versionChecker: any = null;
  showStockNotifcations: boolean;
  checkingAuthorisation: boolean;
  isPreview: any;
  environmentApiWarning: string;
  customClass = "";
  conditionsActive = {};
  showIcons = true;
  navbarFixed = true;
  defaultPadding: string;
  isNavbarVisible = true;
  showBasketBar = true;
  prevScrollPos = document.documentElement.scrollTop ?? 1;
  basketBarInvisible: boolean;


  @HostListener('window:scroll', [])
  onWindowScroll() {

    // Don't hide on Firefox because it's buggy
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

    if (this.deviceDetector.isMobile() && !isFirefox) {

      if (typeof this.basketPreview?.dropdown === "undefined" || (typeof this.basketPreview?.dropdown !== "undefined" && !this.basketPreview?.dropdown.isOpen())) {
        const currentScrollPos = document.documentElement.scrollTop;
        this.isNavbarVisible = (currentScrollPos <= this.prevScrollPos) || currentScrollPos < 50;
        this.prevScrollPos = currentScrollPos;
      } else {
        this.isNavbarVisible = true;
      }
    }
  }

  get headerClass() {
    return this.applicationService.isPartnerPortal() ? 'APPP' : `bg-dark gc_theme gc_${this.selectedCompanyGroup}`;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private authenticateService: AuthenticateService,
    public applicationService: ApplicationService,
    private loadingBar: LoadingBarService,
    public accountService: AccountService,
    private chatService: ChatService,
    private trackingService: TrackingService,
    private yearAgreementService: YearAgreementService,
    private modalService: NgbModal,
    private authenticationService: AuthenticateService,
    private alertService: AlertService,
    private slideshowService: SlideshowService,
    public location: Location,
    private kioskService: KioskService,
    private cacheService: CacheService,
    private storyblokService: StoryblokService,
    private yearAgreementBaseService: YearAgreementBaseService,
    private deviceDetector: DeviceDetectorService,
    private primengConfig: PrimeNGConfig,
    private settingsService: SettingsService,
    private renderer2: Renderer2,
  ) {
    // Check token else redirect to login
    if (this.authenticateService.isAuthorized()) {
      this.applicationService.setLanguage();
      this.authenticateService.validLogin();
      this.applicationService.clearPreorderSettings();
      this.setAbTest();

      this.setFeedbackText();
      if (this.showMainInterface) {
        this.getCompanyGroupSettings(true).then(() => { });
      }
    }

    // Reload when other dealer selected
    window.addEventListener(
      'storage',
      (event) => {
        if (event['key'] && event['key'] === 'refresh') {
          sessionStorage.clear();
          localStorage.removeItem('refresh');
          this.router.navigateByUrl(
            `/${this.applicationService.getSelectCompanyGroupCode()}/home`
          );
          this.applicationService.saveSelectedCompanyGroupCode(
            this.applicationService.getSelectCompanyGroupCode()
          );
          this.getCompanyGroupSettings(true);
          this.init();
        }
      },
      false
    );
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {

    this.primengConfig.ripple = true;
    this.init();
  }

  setAbTest() {
    if (localStorage.getItem("ab-test")) {
      document.body.classList.add(localStorage.getItem("ab-test"));
    } else {
      document.body.classList.remove(localStorage.getItem("ab-test"));
    }
  }

  setNavbar() {
    // Default set Non fixed navbar for < 960 window height
    if (localStorage.getItem("navbar-not-fixed") === null && window.innerHeight <= 960 && !this.deviceDetector.isMobile()) {
      localStorage.setItem("navbar-not-fixed", "1");
    }
    const parentElement = document.getElementsByTagName("main")[0];
    const extraPadding = parentElement.style.getPropertyValue("--extraPaddingTop");
    if (localStorage.getItem("navbar-not-fixed") === "1" && !this.deviceDetector.isMobile() && (!window.location.href.includes("/login"))) {
      parentElement.style.setProperty('--paddingTop', 'calc(24px + var(--extraPaddingTop)');
      // document.documentElement.style.setProperty('--sideBarPosition', 'absolute');
      this.navbarFixed = false;
    } else {
      this.navbarFixed = true;
    }
  }

  setLanguage(languageCode: string) {
    this.translate.use(languageCode);
    this.applicationService.saveSelectedLanguage(languageCode);
  }

  // Check if a custom theme has to be activated
  hasCustomTheme() {

    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const start = moment("20231120")
    const end = moment("20231127")
    if (localStorage.getItem("darkMode")) {
      this.customClass = "custom";
    } else {
      this.customClass = "";
    }
    if (today.isSameOrAfter(start) && today.isSameOrBefore(end) && this.applicationService.getSelectCompanyGroupCode() === "ANL") {
      this.customClass = "custom bf";
    }
    if (this.applicationService.isPartnerPortal()) {
      this.customClass = "partnerPortal";
    }
  }

  init() {
    this.slideshows = Array<Slideshow>();
    this.environmentName = environment.name;
    this.setNavbar();
    this.environmentApiWarning = environment.apiendpoint.includes(
      "gateway.accentry"
    )
      ? "production data!"
      : "";
    console.log(
      'Running on ' + environment.name + ' (' + environment.apiendpoint + ')'
    );

    if (localStorage.getItem('isIE')) {
      alert('Your browser is outdated. Please use a modern browser like Google Chrome or Firefox!');
    }

    // In Storyblok preview mode
    this.isPreview = this.storyblokService.isInEditorMode();

    // Show dropshipment / alerts etc icons
    this.showIcons = !this.applicationService.isPartnerPortal();

    this.showBasketBar = this.applicationService.getSetting("basketbar_enabled", false);

    // Auto login
    this.route.queryParams.subscribe((params) => {
      if (params['key'] && this.router.url.indexOf('autologin') < 0) {
        const redirectUrl = window.location.pathname.replace(
          `key=${params['key']}`,
          `t=1`
        );

        // Set language
        if (params['lang']) {
          const lang = String(params['lang']).toLowerCase();
          this.setLanguage(lang);
        }
        this.router.navigateByUrl(
          `/autologin?key=${params['key']}&redirectUrl=${encodeURIComponent(
            redirectUrl
          )}`
        );
      }
    });

    this.isLoggedIn$ = this.authenticateService.isLoggedIn;

    // Get settings for selected company group
    this.selectedCompanyGroup =
      this.applicationService.getSelectCompanyGroupCode();

    this.applicationService.getSettingsForCompanyGroup(
      this.selectedCompanyGroup
    );

    this.showConditionsModal();
    // Check for changes in kioskmode
    this.kioskService.emitExitKioskMode$.subscribe(() => {
      this.kioskCheckerActive = true;
    });

    // Check for new year agreements
    if (!this.accountService.isHelpdesk() && !this.getKioskMode()) {
      this.agreementChecker();
    }

    this.applicationService.setPageTitle('');

    this.router.events.subscribe((data: any) => {
      if (data.url && data instanceof NavigationEnd) {
        this.redirectKioskIfNecessary();

        this.applicationService.setPageTitle('');

        if (data.url.indexOf('preorder=1') > 0) {
          this.showPreorderBasket = true;
        } else if (this.showPreorderBasket) {
          this.showPreorderBasket = false;
        }
        //this.angulartics2GoogleAnalytics.pageTrack(window.location.href);
        this.trackingService.trackPage(data.url);

        if (!this.initScreenSaver) {
          this.screenSaverActive = false;
        } else {
          this.initScreenSaver = false;
        }
        const currentCompanyGroup =
          this.applicationService.getSelectCompanyGroupCode();

        const urlData = data.url.split('/');

        if (urlData.length > 1) {
          if (urlData[1]) {
            this.selectedCompanyGroup = String(urlData[1]).toUpperCase();
          }

          // Check if user has access to cgc
          this.selectedCompanyGroup =
            this.applicationService.checkSelectedCompanyGroupCode(
              this.selectedCompanyGroup
            );

          // Set loader color
          this.setLoaderColor(this.selectedCompanyGroup);

          // Save selected cgc
          if (currentCompanyGroup !== this.selectedCompanyGroup) {
            this.applicationService.saveSelectedCompanyGroupCode(
              this.selectedCompanyGroup
            );
          }

          // Show chat bubble
          this.chatService.initChat();
        }

        // Show interface elements based on signin
        const currentUrl = data.url.toLowerCase();
        if (
          currentUrl.includes(".aspx") ||
          currentUrl.includes("/login") ||
          currentUrl.includes("/logout") ||
          currentUrl.includes("/resetpassword") ||
          currentUrl.includes("/verify") ||
          currentUrl.includes("/outdated") ||
          currentUrl.includes("/activateaccount") ||
          currentUrl.includes("/migrateaccount") ||
          currentUrl.includes("/start") ||
          currentUrl.includes("/register")
        ) {
          this.showMainInterface = false;
          this.showBase = false;
          this.agreementInterval = null;
          document.body.classList.remove("helpdeskMode");
        } else if (data.url && this.authenticateService.isAuthorized()) {
          this.basketBarInvisible = window.location.href.includes("/basket") || window.location.href.includes("/checkout");
          if (!this.showMainInterface) {
            this.checkingAuthorisation = true;
            this.applicationService.showLoader(true);
            this.authenticateService.checkAuthorisation().then((hasVersion) => {
              this.applicationService.getAppVersion(hasVersion).then(() => {
                this.applicationService.hideLoader();
                this.showMainInterface = !window.location.href.includes("hide-ui");
                this.showBase = true;
                this.checkingAuthorisation = false;

                // Get account information before loading the notications HUB
                this.accountService.getAccountInformation().then(() => {
                  this.showStockNotifcations = true;
                });

                // Show main interface of the app (not on login)
                timer(500).subscribe(() => {
                  this.showNav = true;
                });

                // CHAT initialize
                this.showChatButton = this.chatService.initChat();

                // Get the account type
                this.isDealer = this.accountService.isDealer();
                this.isHelpdesk = this.accountService.isHelpdesk();

                this.hasCustomTheme();

                if (this.isHelpdesk) {
                  document.body.classList.add("helpdeskMode");
                } else {
                  document.body.classList.remove("helpdeskMode");
                }

                // Show sideshows / yearagreements
                if (
                  data.url.toLowerCase().indexOf("/home") >= 0 &&
                  !this.getKioskMode()
                ) {
                  this.getSlideshows();
                  if (!this.accountService.isHelpdesk()) {
                    this.agreementChecker();
                    this.setFeedbackText();
                  }
                }
              });
            });
          }
        }
      }

      this.applicationService.emitChangeCompanyGroupChanged$.subscribe(
        (response) => {
          this.showChatButton = this.chatService.initChat();
          this.selectedCompanyGroup = response;
          this.cacheService.clearCache();
          this.alertService.closePopup();
          this.hasCustomTheme();
          this.showConditionsModal();
        }
      );
    });

    this.applicationService.emitShowLoader$.subscribe((response) => {
      this.showSpinner = response['spinner'];

      if (response['show']) {
        this.loadingBar.start();
      } else {
        this.loadingBar.complete();
        this.showSpinner = false;
      }
    });
    this.getShowroomMode();
    this.getKioskMode();
    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
      this.getKioskMode();
    });
    this.redirectKioskIfNecessary();
  }

  setLoaderColor(selectedCompanyGroup: string) {
    switch (selectedCompanyGroup) {
      default:
      case 'ANL':
        this.loaderColor = '#35b552';
        break;
      case 'LAP':
        this.loaderColor = '#589DE0';
        break;
      case 'WG':
        this.loaderColor = '#f4ad24';
        break;
      case 'VAR':
      case 'GHO':
        this.loaderColor = 'rgba(238, 74, 61, 0.7)';
        break;
    }
  }

  showConditionsModal() {

    // General Conditions - Storyblok PageIds 
    const pageIds = {
      GHO: "nutzungsbedingungen-datenschutz",
      WG: "nutzungsbedingungen-datenschutz",
      COM: "condiciones-generales",
    }
    this.checkSignedConditions(pageIds[this.applicationService.getSelectCompanyGroupCode()], "general-conditions", "GENERAL_TERMS_AND_CONDITIONS");

    // Embargo Conditions - Storyblok PageIds 
    const pageIdsEmbargo = {
      GHO: "embargo-nda-gho",
      WG: "embargo-nda-wg",
    }

    // Check for dealers WG Bikes
    if (this.applicationService.checkIfDealerHasCompany(101)) {
      this.checkSignedConditions(pageIdsEmbargo[this.applicationService.getSelectCompanyGroupCode()], "nda-conditions", "Extension of the embargo for our bikemodels");
    }
  }

  // Check if the user has signed the agreement
  checkSignedConditions(pageId: string, ndaKey: string, modalTitle: string) {

    if (typeof pageId !== "undefined" && !this.conditionsActive[pageId] && !window.location.href.includes("hide-ui")) {
      this.conditionsActive[pageId] = true;
      this.accountService.getNda(ndaKey).then((response: SignedCondition) => {
        const hasSignedConditions = response !== null;
        if (!hasSignedConditions && !this.accountService.isHelpdesk() && typeof pageId !== "undefined") {
          const reference = this.modalService.open(NdaCheckComponent, {
            container: "#modalContainer",
            windowClass: "fixed-modal medium",
            backdrop: "static",
            backdropClass: "blurred-bg",
            centered: true,
            keyboard: false
          });
   
          reference.componentInstance.section = ndaKey;
          reference.componentInstance.ts = "";
          reference.componentInstance.pageId = pageId;
          reference.componentInstance.title = modalTitle;
          reference.componentInstance.showCloseButton = false;
        } else {
          this.conditionsActive[pageId] = false;
        }
      });
    }
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  getKioskMode() {
    this.kioskMode = this.applicationService.getKioskMode(this.kioskMode);
    return this.kioskMode;
  }

  redirectKioskIfNecessary() {
    const path = this.location.path().toLowerCase();
    if (this.kioskMode) {
      if (path.includes('login') || path.includes('logout')) {
        return false;
      }

      if (!path.includes('kiosk')) {
        this.router.navigateByUrl(`${this.selectedCompanyGroup}/kiosk/home`);
      }
    } else {
      if (path.includes('kiosk')) {
        this.router.navigateByUrl('/');
      }
    }
  }

  onActivate(event) {
    this.toTop();
  }

  toTop() {
    document.body.scrollTop = 0;
    const html = document.documentElement;
    if (html) {
      html.scrollTop = 0;
    }


    const modal = document.getElementsByClassName('modal');
    if (modal && modal.length) {
      const modalContent = modal[0] as HTMLElement;
      modalContent.scrollTop = 0;
    }
  }

  getCompanyGroupSettings(clear = false) {
    const promise = new Promise((resolve) => {
      // Check if there are setting stored
      if (
        !clear &&
        this.applicationService.getAvailableCompanyGroups().length
      ) {
        this.applicationService.getSettingsForCompanyGroup(
          this.selectedCompanyGroup
        );
        resolve(true);
      } else {
        this.accountService
          .getAvailableCompanyGroups()
          .subscribe((response: ApiResponse) => {
            if (response.result && response.result.length) {
              this.applicationService.saveCompanyGroups(response.result);
              this.applicationService.getSettingsForCompanyGroup(
                this.selectedCompanyGroup
              );
              resolve(true);
            } else {
              this.authenticationService.logout();
            }
          });
      }
    });
    return promise;
  }

  showChat() {
    this.chatService.showChat();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    if (event.code === 'Backslash' && event.ctrlKey) {
      this.screenSaverActive = !this.screenSaverActive;
    }
  }

  setScreenSaver(data) {
    this.screenSaverActive = data;
  }

  agreementChecker() {
    if (
      !this.agreementInterval &&
      sessionStorage.getItem(
        `yearagreement_shown_${new Date().getFullYear()}`
      ) === null
    ) {
      if (this.applicationService.getSelectCompanyGroupCode() === 'ANL') {
        const countryCode = localStorage.getItem("country");
        if (["NL", "BE"].includes(countryCode)) {
          this.getOpenAgreements(false, YearAgreementType.bikes);
        }

        this.getOpenAgreements(true, YearAgreementType.parts);
      }
      if (
        this.applicationService.getSelectCompanyGroupCode() === 'GHO' ||
        this.applicationService.getSelectCompanyGroupCode() === 'WG'
      ) {
        this.getOpenAgreements(false, YearAgreementType.dach);
      }
    }
  }

  // Get open yearagreements
  getOpenAgreements(useNewApi: boolean, type?: YearAgreementType) {
    if (this.showMainInterface) {
      const service =
        !useNewApi
          ? this.yearAgreementService.getAccountOpenAgreements(type)
          : this.yearAgreementBaseService.openAgreements();

      service.subscribe((apiResponse: ApiResponse) => {
        const storageKey =
          `yearagreement${type ? type : "general"}_shown_` + new Date().getFullYear();
        if (
          apiResponse &&
          apiResponse.success &&
          apiResponse.result &&
          apiResponse.result.length &&
          sessionStorage.getItem(storageKey) === null
        ) {
          const modalRef = this.modalService.open(
            ShowDealerAgreementsComponent,
            {
              centered: true,
              size: 'lg',
              container: '#modalContainer',
            }
          );
          modalRef.componentInstance.agreements = (
            apiResponse.result as AccountAgreement[]
          ).reverse();
          const latestAgreement = modalRef.componentInstance.agreements[0];
          modalRef.componentInstance.selectedAgreement = latestAgreement;
          modalRef.componentInstance.id = latestAgreement.id;
          modalRef.componentInstance.useBase =
            type !== YearAgreementType.bikes &&
            type !== YearAgreementType.dach;

          modalRef.componentInstance.type =
            typeof latestAgreement.type === "undefined"
              ? type
              : latestAgreement.type;
          modalRef.componentInstance.getPdf(
            latestAgreement.id,
            latestAgreement.year
          );

          sessionStorage.setItem(storageKey, 'true');
        }
      });
    }
  }

  /**
 * Retrieves slideshows from the API and displays them in a modal
 */
  getSlideshows() {
    if (this.cacheService.needRefresh(CacheType.slideshows)) {
      this.slideshowService
        .getSlideshows()
        .subscribe((apiResponse: ApiResponse) => {
          if (
            apiResponse &&
            apiResponse.success &&
            apiResponse.result &&
            apiResponse.result.items &&
            apiResponse.result.items.length
          ) {
            this.slideshows = apiResponse.result.items as Array<Slideshow>;
            this.slideShowsChecked = true;
            const unseenSlideshows = Array<Slideshow>();
            for (let slideshow of this.slideshows) {
              if (!this.slideshowService.checkIfSlideshowSeen(slideshow)) {
                unseenSlideshows.push(slideshow);
              }
            }

            let viewIndex = 0;
            // Show modal
            const showModal = (slideshow: Slideshow) => {
              const modalRef = this.modalService.open(SlideshowModalComponent, {
                ariaLabelledBy: 'modal-basic-title',
                size: 'lg',
                windowClass: 'large',
                container: '#modalContainer',
                centered: true,
              });

              modalRef.componentInstance.slideshow = slideshow;
              viewIndex++;
              modalRef.result.catch(() => {
                if (unseenSlideshows[viewIndex]) {
                  showModal(unseenSlideshows[viewIndex]);
                }
              });
            };

            if (unseenSlideshows && unseenSlideshows.length) {
              showModal(unseenSlideshows[0]);
            }
            this.cacheService.dataLoaded(CacheType.slideshows);
          }
        });
    }
  }

  public exitKioskMode() {
    this.kioskMode = false;
    this.applicationService.saveKioskMode(false);
    if (this.router.url.includes('kiosk')) {
      this.router.navigateByUrl('/');
    }
  }

  public pinAuth() {
    this.exitKioskMode();
  }

  closeBasketPreview() {
    if (this.basketPreview) {
      this.basketPreview.hideDropdown();
    }
  }

  private setFeedbackText() {
    const elements = document.querySelectorAll(
      "div[class*='_feedback_minimized_label_text']"
    );
    if (elements && elements.length) {
      const feedbackButton = elements[0];
      feedbackButton.innerHTML = this.translate.instant('FEEDBACK');
    }
  }

  updateApplication() {
    this.applicationService.updateApplication();
  }

  disableKioskMode() {
    this.kioskCheckerActive = false
  }

  scrollTop(event) {
    window.scroll(0, 0);
  }
}

