import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrderLine } from "src/app/interfaces/financial/openorders/order-line";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { EditOpenOrderLineInput } from "src/app/interfaces/financial/openorders/edit-open-order-line-input";
import { ApiResponse } from "src/app/interfaces/api-response";
import { FinancialService } from "src/app/services/financial.service";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "underscore";
import { ApplicationService } from "src/app/services/application.service";
import { HelperService } from "src/app/services/helper.service";
import { EditOpenOrderChangeBatteryComponent } from "../edit-open-order-change-battery/edit-open-order-change-battery.component";
import { Article } from "src/app/interfaces/article";
import { SearchService } from "../../../../services/search.service";
@Component({
  selector: "app-edit-open-order",
  templateUrl: "./edit-open-order.component.html",
  styleUrls: ["./edit-open-order.component.scss"],
})
export class EditOpenOrderComponent implements OnInit {
  order: OrderLine;
  originalOrder: OrderLine;
  splittedOrder: OrderLine;
  splitted: boolean;
  currentDeliveryWeek: string;
  selectableDeliveryWeek: string[];
  shiftingWeeks: string[];
  originalQuantity: number;
  newSplittedQuantity: number;
  @Input() shippingAddressGroup: ShippingAddressGroup;
  splittedAddressGroups: ShippingAddressGroup;
  shiftingBackWeeks: string[];
  shippingId: string;
  weekChanged: boolean;
  newBattery: Article;
  currentBattery: Article = null;
  currentBatteryLoading: boolean;
  loading: boolean;

  constructor(
    public modal: NgbActiveModal,
    private financialService: FinancialService,
    private alertService: AlertService,
    public applicationService: ApplicationService,
    private translateService: TranslateService,
    private helperService: HelperService,
    private modalService: NgbModal,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    this.originalQuantity = this.order.quantity;
    this.splittedOrder = new OrderLine();

    const currentDeliveryDate = this.order.delivery_week_or_date.split(" ");
    this.currentDeliveryWeek =
      String(currentDeliveryDate[1]) + String(currentDeliveryDate[0]);

    if (this.order.backward_shifting_weeks) {
      const weeksArray = this.order.backward_shifting_weeks.split(";");
      if (weeksArray.length) {
        this.selectableDeliveryWeek = this.parseWeeks(weeksArray);
        this.shiftingBackWeeks = this.selectableDeliveryWeek;
      }
    }

    if (this.order.battery) {
      this.getCurrentBattery();
    }

    if (
      this.order.can_push_fordward_delivery_week &&
      this.applicationService.getSelectCompanyGroupCode() !== "ANL"
    ) {
      this.getShiftingWeeks();
    }
    this.order.delivery_week_or_date = this.currentDeliveryWeek;
  }

  parseWeeks(weeksArray) {
    const selectableDeliveryWeek = Array<string>();
    weeksArray.forEach((week) => {
      if (week.trim()) {
        const deliveryDate = week.split("^");
        selectableDeliveryWeek.push(deliveryDate[0]);
      }
    });
    return selectableDeliveryWeek;
  }

  getSelectedShippingId() {
    return Number(this.order.shipping_id);
  }

  setShippingAddress(order: OrderLine, selectedShippingId: string) {
    const selectedShippingAddress = _.findWhere(
      this.shippingAddressGroup.shipping_addresses,
      { id: Number(selectedShippingId) }
    );
    order.shipping_id = String(selectedShippingAddress.id);
    order.shipping_code = String(selectedShippingAddress.address_type);
  }

  getShiftingWeeks() {
    this.shiftingWeeks = Array<string>();
    this.financialService
      .getShiftingWeeks(this.order)
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.shiftingWeeks = apiResponse.result.split("^");
        }
      });
  }

  // Split the order
  splitOrder() {
    // Create splitted order
    this.splittedOrder.remark = this.order.remark;
    this.splittedOrder.sold = this.order.sold;
    this.splittedOrder.delivery_week_or_date = this.order.delivery_week_or_date;
    this.splittedOrder.quantity = Math.floor(this.order.quantity / 2);

    // Clone selectable shipping addresses
    this.splittedAddressGroups = Object.assign({}, this.shippingAddressGroup);

    // Divide quantity
    this.order.quantity = Math.ceil(this.order.quantity / 2);
    this.splitted = true;
  }

  // Check changed quantity
  splitQuantityChange(event) {
    const value = Number(event.srcElement.value);
    const newQuantity = this.originalQuantity - value;
    if (newQuantity > 0 && newQuantity <= this.originalQuantity && value > 0) {
      this.order.quantity = newQuantity;
      this.splittedOrder.quantity = value;
      this.newSplittedQuantity = value;
    } else {
      event.srcElement.value = this.newSplittedQuantity;
      event.preventDefault();
      return false;
    }
  }

  // Save changed order
  saveOpenOrder() {
    if (!this.weekChanged) {
      this.order.delivery_week_or_date = null;
    }
    this.loading = true;
    this.financialService
      .editOrder(this.order, this.splitted ? this.splittedOrder : null)
      .subscribe((response: ApiResponse) => {
        this.loading = false;
        if (this.helperService.checkResponse(response)) {
          this.alertService.showInfoNotification(
            this.translateService.instant("SAVED")
          );

          if (this.newBattery && this.newBattery.id) {
            this.financialService
              .updateOpenOrderBattery(
                this.originalOrder,
                this.newBattery.single_article_code
              )
              .subscribe((apiResponse: ApiResponse) => {
                if (this.helperService.checkResponse(apiResponse)) {
                  this.modal.dismiss();
                  this.originalOrder = this.order;
                  this.financialService.emitChange(response);
                } else {
                  this.alertService.showErrorNotification(
                    apiResponse ? apiResponse.result : "ERROR"
                  );
                }
              });
          } else {
            this.originalOrder = this.order;
            this.financialService.emitChange(response);
            this.modal.dismiss();
          }
        } else {
          this.alertService.showDangerNotification(
            response.result
              ? response.result
              : this.translateService.instant("ERROR")
          );
        }
      });
  }

  changeBattery() {
    const modalRef = this.modalService.open(
      EditOpenOrderChangeBatteryComponent,
      {
        centered: true,
        size: "lg",
        container: "#modalContainer",
      }
    );
    const article = { ...this.order.article };
    article.mandatory_battery = true;
    modalRef.componentInstance.article = article;

    // preselect battery
    if (this.order.battery) {
      modalRef.componentInstance.preSelectedBattery = this.order.battery;
    }
    modalRef.componentInstance.orderLine = this.originalOrder;
    modalRef.componentInstance.batterySelected.subscribe((battery: Article) => {
      this.newBattery = battery;
      modalRef.close();
    });
  }

  getCurrentBattery() {
    this.currentBatteryLoading = true;
    this.searchService
      .articleDetails(null, this.order.battery)
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.currentBattery = apiResponse.result;
        }
        this.currentBatteryLoading = false;
      });
  }
}
