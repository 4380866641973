import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  SimpleChange,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { SearchService } from '../../../services/search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from 'src/app/interfaces/bread-crumb';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../../../services/application.service';
import { AddToBasketModalComponent } from '../../modal-components/add-to-basket-modal/add-to-basket-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Article } from 'src/app/interfaces/article';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { SetItemsComponent } from '../set-items/set-items.component';
import { ChatService } from 'src/app/services/chat.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { ArticleImageCarouselComponent } from '../article-image-carousel/article-image-carousel.component';
import { AccountService } from 'src/app/services/account.service';
import { ArticleService } from 'src/app/services/article.service';
import { Observable } from 'rxjs';
import { RecentlyViewedComponent } from '../recently-viewed/recently-viewed.component';
import { environment } from 'src/environments/environment';
import { ArticleModalComponent } from '../../modal-components/article-modal/article-modal.component';
import { HelperService } from 'src/app/services/helper.service';
@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
          overflow: 'hidden',
        }),
        animate('425ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ArticleDetailsComponent implements OnInit {
  @Input() modelId: string;
  @Input() showDetailed = true;
  @Input() selectForWarrantyMode = false;
  @Input() showInModal = false;
  @Input() bomQuantity: number;
  @Input() openArticleInModal = false;
  @Output() showArticleDetails: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(SetItemsComponent) setItems: SetItemsComponent;
  @ViewChild('carouselContainer')
  carouselContainer: ArticleImageCarouselComponent;
  @ViewChild('recentlyViewed') recentlyViewed: RecentlyViewedComponent;
  breadcrumbs: Breadcrumb[];
  showBreadCrumbs = true;
  dataLoaded: boolean;
  instant: boolean;
  specsCollapsed = true;
  articleXhr: any = null;
  selectedArticle = null;
  showroomMode = false;
  @Input() textCollapsed: boolean;
  article: Article;
  selectedEcommerceTab = 'alternatives';
  placeholder = '';
  assetsPath = 'assets/images/';
  showCarousel = false;
  articleNotFound = false;
  fromSearch = false;
  specsCollapsable: boolean;
  preOrder: boolean;
  expanded: boolean;
  loading: boolean;
  @Input() leaseRequest: boolean;
  framenumber: any;
  hideLinks: boolean;
  showBom: boolean;
  showRecent: boolean;
  show360: boolean;
  hasDealerSupportArticles: boolean;
  @Input() showQrCode: boolean;
  environmentName: string;
  groupDescriptions: string;
  modelTemporarilyUndeliverable: boolean;
  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private modalService: NgbModal,
    public applicationService: ApplicationService,
    public chatService: ChatService,
    private trackingService: TrackingService,
    private accountService: AccountService,
    private articleService: ArticleService,
    private helperService: HelperService
  ) {
    this.article = new Article();
    this.environmentName = environment.name;
  }

  ngOnInit() {
    // Check if page is visited by url
    this.route.params.subscribe((params) => {
      this.articleNotFound = false;
      if (
        params['id'] &&
        !this.modelId &&
        window.location.href.indexOf(params['id']) >= 0
      ) {
        this.instant = false;
        this.modelId = params['id'];
        this.article.id = parseFloat(params['id']);
        if (params['articleCode'] || params['text']) {
          this.article.single_article_code =
            params['articleCode'] ?? params['text'];
          this.selectedArticle = params['articleCode'] ?? params['text'];
        }
        if (this.route.snapshot.queryParamMap.get('framenumber')) {
          this.framenumber =
            this.route.snapshot.queryParamMap.get('framenumber');
        }
        if (this.article.frame_number) {
          this.framenumber = this.article.frame_number;
        }

        this.getArticleDetails();
      }
    });
    this.getShowroomMode();
    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
    });

    this.showBom =
      this.accountService.getAccountType() !== 'DLR' ||
      this.applicationService.getSelectCompanyGroupCode() !== 'WG';
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  setArticleBreadCrumbs() {
    this.breadcrumbs = [];

    if (this.article?.description) {
      let articleGroup = null;
      let subArticleGroup = null;
      let subSubArticleGroup = null;

      try {
        articleGroup = this.article.nested_groups
          ? this.article.nested_groups[0]
          : null;
        subArticleGroup = this.article.nested_groups
          ? this.article.nested_groups[0].sub_groups[0]
          : null;
        subSubArticleGroup = subArticleGroup
          ? this.article.nested_groups[0].sub_groups[0].sub_groups[0]
          : null;
      } catch (err) {}

      const companyGroupCode =
        this.applicationService.getSelectCompanyGroupCode();
      this.breadcrumbs.push(
        new Breadcrumb(
          this.translate.instant('HOME'),
          companyGroupCode + '/home'
        ),
        new Breadcrumb(
          this.translate.instant('SEARCH'),
          companyGroupCode +
            `/search${
              this.fromSearch ? '?dt&fromDetails=true' : '?fromDetails=true'
            }`
        )
      );

      if (articleGroup && articleGroup.description?.length) {
        this.breadcrumbs.push(
          new Breadcrumb(
            articleGroup.description,
            companyGroupCode +
              `/search#filter/COMMON=&groups=${articleGroup.code}&fromDetails=true`
          )
        );
      }
      if (subArticleGroup && subArticleGroup.description?.length) {
        this.breadcrumbs.push(
          new Breadcrumb(
            subArticleGroup.description,
            companyGroupCode +
              `/search#filter/COMMON=&groups=${articleGroup.code},${subArticleGroup.code}&fromDetails=true`
          )
        );
      }
      if (subSubArticleGroup?.description?.length) {
        this.breadcrumbs.push(
          new Breadcrumb(
            subSubArticleGroup.description,
            companyGroupCode +
              `/search#filter/COMMON=&groups=${articleGroup.code},${subArticleGroup.code},${subSubArticleGroup.code}&fromDetails=true`
          )
        );
      }
      this.breadcrumbs.push(
        new Breadcrumb(
          `${this.article.description} ${
            this.article.caption ? this.article.caption : ''
          }`,
          '#'
        )
      );
      const groupDescriptions = `${articleGroup?.description}/${subArticleGroup?.description}/${subSubArticleGroup?.description}`;
      this.groupDescriptions = groupDescriptions.replace(/\/undefined/g, '');
    }
  }

  showInstant(expanded?: boolean) {
    this.modelId = String(this.article.id);
    this.selectedArticle = this.article.single_article_code
      ? this.article.single_article_code
      : '';
    this.instant = true;
    if (expanded === true) {
      this.expanded = true;
    }
    this.setPlaceholder();
    this.getArticleDetails();
  }

  private setPlaceholder() {
    if (
      this.article.article_type &&
      this.article.article_type.startsWith('F')
    ) {
      this.placeholder = this.assetsPath + 'bicycle-solid.svg';
    } else {
      this.placeholder = this.assetsPath + 'part.svg';
    }
  }

  getArticleDetails() {
    let service: Observable<Object> = null;
    this.show360 = false;

    if (this.loading) {
      return false;
    }
    this.dataLoaded = false;
    this.loading = true;

    if (this.articleXhr) {
      this.articleXhr.unsubscribe();
    }

    if (this.showDetailed && !this.showInModal) {
      document.body.scrollTop = 0;
      const html = document.documentElement;
      if (html) {
        html.scrollTop = 0;
      }
    }

    if (!this.preOrder) {
      service = this.searchService.articleDetails(
        this.article.id,
        this.preOrder ? null : this.selectedArticle,
        this.framenumber ? this.framenumber : null
      );
    } else {
      service = this.articleService.preorderArticleDetails(this.article.id);
    }

    const selectedArticleCode = this.article.single_article_code;
    if (
      this.modelId ||
      (this.selectedArticle &&
        (!this.framenumber || (this.framenumber && this.article.id)))
    ) {
      this.articleXhr = service.subscribe((response: ApiResponse) => {
        this.dataLoaded = true;
        this.loading = false;
        if (response && response.result && response.result instanceof Object) {
          const selectedForCompare = this.article.selected_for_compare;
          const articleOem = this.article.article_code_oem;
          const stockNotification = this.article.has_stock_notification;
          this.article = response.result;
          this.article.article_code_oem = articleOem;
          this.article.has_stock_notification = stockNotification;
          this.setPlaceholder();

          this.article.selected_for_compare = selectedForCompare;
          if (selectedArticleCode) {
            this.article.single_article_code = selectedArticleCode;
          }
          if (this.recentlyViewed) {
            this.recentlyViewed.getArticles();
            this.showRecent = true;
          }
          this.article.quantity = this.article.min_order_amount
            ? this.article.min_order_amount
            : 1;
          this.article.parsedSpecs = this.searchService.parseSpecs(
            this.article.specs,
            this.article
          );

          this.article.parsedImages = this.searchService.parseImages(
            this.article
          );

          this.article.text = this.helperService.nl2br(this.article.text);

          if (this.article.promotion) {
            this.article.promotion_price = this.article.net_price;
          }

          this.getTempUndeliverable();

          // reset carousel
          this.showCarousel = false;
          setTimeout(() => {
            this.showCarousel = true;
          }, 0);

          this.specsCollapsed =
            this.article.parsedSpecs.length > 3 &&
            (this.article.alternative_ecommerce ||
              this.article.cross_sell_ecommerce ||
              this.chatService.hasChat());
          this.setArticleBreadCrumbs();

          this.trackingService.articleDetailsView(
            this.article,
            this.groupDescriptions
          );

          this.specsCollapsable = this.specsCollapsed;

          if (this.article.nett_prices && this.article.nett_prices.length > 1) {
            this.article.has_different_prices = true;
          }

          this.setArticleBreadCrumbs();
          this.articleNotFound = false;
          this.articleService.updateRecentlyViewed(this.article);

          setTimeout(() => {
            this.showArticleImage(this.article);
          }, 0);
        } else {
          this.setDataFromInstant();
          if (this.articleNotFound && this.framenumber) {
            this.searchService
              .searchInstant(this.framenumber)
              .subscribe((apiResponse: ApiResponse) => {
                if (
                  apiResponse &&
                  apiResponse.result &&
                  apiResponse.result[0] instanceof Object
                ) {
                  this.article = apiResponse.result[0];
                  this.setDataFromInstant();
                  this.hideLinks = true;

                  // Get regular article data to check if the article may be ordered
                  if (this.framenumber) {
                    this.framenumber = '';
                    this.getArticleDetails();
                  }
                }
              });
          }
        }
      });
    }
  }

  getTempUndeliverable() {
    if (this.article.matrix.options && this.article.matrix.options.length) {
     this.modelTemporarilyUndeliverable = this.articleService.getTempUndeliverable(this.article.matrix, this.article.single_article_code)
    }
  }

  setDataFromInstant() {
    this.articleNotFound =
      this.article && this.article.description ? false : true;
    if (!this.articleNotFound) {
      this.showCarousel = true;
      this.article.parsedImages = this.searchService.parseImages(this.article);
      this.article.f_img = [''];
    }
  }

  selectArticle(selectedArticle: Article) {
    this.searchService
      .articleDetails(
        null,
        this.preOrder ? null : selectedArticle.single_article_code
      )
      .subscribe((response: ApiResponse) => {
        if (response && response.success) {
          const articleDetails = response.result as Article;
          this.showArticleImage(articleDetails);
          const article = Object.assign(this.article, selectedArticle);
          article.parsedSpecs = this.searchService.parseSpecs(
            articleDetails.specs
          );
          article.single_ean = articleDetails.single_ean;
          article.single_article_code = articleDetails.single_article_code;
          article.authorized_to_order = articleDetails.authorized_to_order;
          article.authorized_to_place_in_basket =
            articleDetails.authorized_to_place_in_basket;
          article.single_article_code_short =
            articleDetails.single_article_code_short;
          article.supplier_code = articleDetails.supplier_code;
          article.caption = articleDetails.caption;
          article.supplier_code = articleDetails.supplier_code;
          article.dropshipment = articleDetails.dropshipment;
          article.promotion = articleDetails.promotion;

          if (article.promotion) {
            article.promotion_price = articleDetails.net_price;
          }

          article.volume_price = articleDetails.volume_price;

          if (articleDetails.net_price) {
            article.net_price = articleDetails.net_price;
          }
          article.has_different_prices = false;
          this.article = article;
        }
      });

    this.location.go(
      this.applicationService.getSelectCompanyGroupCode() +
        '/articleDetails/' +
        this.article.id +
        '/' +
        this.article.single_article_code
    );
  }

  showArticleImage(articleDetails: Article) {
    if (
      articleDetails.f_img &&
      articleDetails.f_img.length &&
      this.carouselContainer
    ) {
      this.carouselContainer.gotoImage(articleDetails.f_img[0]);
    }
  }

  addToBasket() {
    const modalRef = this.modalService.open(AddToBasketModalComponent, {});
    modalRef.componentInstance.article = this.article;
  }

  gotoItem(article: Article, event) {
    if (this.openArticleInModal) {
      this.openModal(article);
    } else {
      this.location.go(
        this.applicationService.getSelectCompanyGroupCode() +
          '/articleDetails/' +
          article.id +
          '/' +
          (article.single_article_code ? article.single_article_code : '')
      );
      this.showArticleDetails.emit(article);
    }

    event.preventDefault();
    return false;
  }

  openModal(article: Article) {
    const modalRef = this.modalService.open(ArticleModalComponent, {
      size: 'lg',
      container: '#modalContainer',
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article.id;
    modalRef.componentInstance.showDetailed = true;
    modalRef.componentInstance.showInModal = true;
    modalRef.componentInstance.selectedArticle = article.single_article_code;
    modalRef.componentInstance.article.img = article.images;
  }
}
