import { Price } from "./price";
import { ArticleMatrix } from "./article-matrix";
import { ArticleType, AvailableArticleTypes } from "./article-type";
import { NettPriceResult } from "./nett-price-result";
import { SimpleChanges } from "@angular/core";
import { LeasePrice } from "./lease/lease-price";
import { ArticleGroup } from "./articleGroup";
import { Specification } from "./specification";

export interface Article {
  alternative_ecommerce: boolean;
  always_order: boolean;
  article_code_oem?: string;
  article_codes: string[] | null;
  article_details: Article;
  article_type: AvailableArticleTypes;
  authorized_to_order: boolean;
  authorized_to_place_in_basket: boolean;
  authorized_to_return: boolean;
  base_collection: boolean;
  bico: boolean;
  bom_articles: Article[];
  bom: boolean;
  brand: string;
  caption: string | "";
  call_dealersupport: boolean;
  carry_over: boolean;
  colors?: string[] | null;
  company_id: string;
  composed: boolean;
  cross_sell_ecommerce: boolean;
  delivery_week: string;
  delivery_year: string;
  description: string;
  dropshipment: boolean;
  ean: string;
  enabled: boolean;
  f_img: string[];
  frame_number?: string;
  frame_number_entry?: boolean;
  gross_price: Price;
  has_badge: boolean;
  has_different_prices: boolean;
  has_flex_batt: boolean;
  has_mandatory_item: boolean;
  has_replacement: boolean;
  hide_nett_price: boolean;
  id: number;
  images?: string[] | null;
  img: any[] | null;
  img3d: any[] | null;
  is_favorite: boolean;
  is_lease: boolean;
  is_mandatory_item: boolean;
  is_mandatory: boolean;
  item_type: ItemType;
  key: string;
  keyDown: boolean;
  lease_details: any;
  lease_price: LeasePrice[] | null;
  lease_start_price: Price;
  mandatory_battery: boolean;
  matrix: ArticleMatrix;
  max_dealer_stock: number;
  min_dealer_stock: number;
  min_order_amount: number;
  model_code: string;
  nested_groups: any;
  net_price: Price;
  nett_prices: NettPriceResult[] | null;
  new: boolean;
  no_img: boolean;
  oem: number;
  option_count: number;
  parsedImages: string[];
  parsedSpecs: any[];
  product_group_codes: string[];
  promotion_price: Price;
  promotion: boolean;
  quantity: number;
  remarks: string;
  replacement_article: {};
  retail_price: Price;
  season: string;
  selected_for_compare: boolean;
  selected_replenishment_article: string;
  selected: boolean;
  setitem: boolean;
  shopType: string;
  single_article_code_short: string;
  single_article_code: string;
  single_ean: string;
  sold: boolean;
  specs: any[];
  speed_pedelec: boolean;
  stock_date: string;
  stock_status: number;
  stock_week: number;
  stock_year: number;
  stock_positions: StockPosition[];
  stock: number;
  supplier_code: string;
  text: string;
  thumbnail: string;
  thumbs?: string[] | null;
  uncheckable: boolean;
  temporarily_undeliverable: boolean;
  usps: any[] | null;
  value: string;
  volume_price: any[];
  has_stock_notification: boolean;
  battery_article_code: string;
  error: boolean;
  up_sell_ecommerce: string;
  warehouse: string;
  warehouse_delivery_week: number;
  warehouse_delivery_year: number;
  store_packaging: Specification | null;
  out_of_delivery: boolean;
  batteries: Article[];
}

export enum ItemType {
  "Bicycles" = "Bicycles",
  "Parts" = "Parts",
}


export interface StockPosition {
  is_virtual: boolean;
  stock: number;
  stock_status: number;
  stock_date: string;
  stock_week: number;
  stock_year: number;
  warehouse: string;
  warehouse_stock_date_formatted: string;
}
export class Article implements Article {
}
