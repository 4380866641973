<ng-template #rt let-r="result" let-t="term">
  <div class="text-center w-100" *ngIf="!resultCount && r.index === 0">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </div>
  <div *ngIf="resultCount > 1">
    <div class="d-flex px-2" *ngIf="r.model_code" (click)="itemSelectMode = true">
      <span class="text-center img-container d-inline-block" [ngClass]="r.index">
        <app-article-image class="text-center d-inline-block" [articleType]="r.article_type" [imageUrl]="r.thumbs[0]"
          *ngIf="r.thumbs && r.thumbs.length" [description]="r.description"
          [classList]="'img-thumbnail'"></app-article-image>
      </span>
      <app-stock-status [article]="r" class="ms-1">
      </app-stock-status>
      <span class="description flex-grow-1"><b [innerHTML]="r.description"></b>
        <small class="mx-2 gray d-inline" [innerHTML]="r.caption"></small>
        <app-highlight-badge [article]="r" class="d-flex"></app-highlight-badge>
        <small class="d-block">
          <app-retail-price [showLabel]="true" [price]="r.retail_price"></app-retail-price>
          <ng-container *ngIf="r.retail_price">. </ng-container>
        </small>
      </span>
      <small class=" text-end">
        {{ r.single_article_code }} {{ !['RAL','ANL'].includes(selectedCompanyGroup)?r.season:'' }}<br />
        <div (click)="gotoBom($event, r)"
          class="mb-1 btn btn-dark btn-sm clickable withOpacity d-inline-flex align-items-center position-relative"
          *ngIf="r.bom && showBom">
          <span class="d-sm-inline-block d-none">{{ "BOM" | translate }}&nbsp;</span>
          <ng-container *ngTemplateOutlet="exploded"> </ng-container>
        </div>
      </small>
    </div>
    <ng-container *ngIf="!r.model_code && r.id">
      <div class="p-2 clickable withOpacity" [attr.data-title]="'GROUPS' | translate" [ngClass]="'group_' + r.index"
        (click)="itemSelectMode = true; gotoGroup(r)">
        <b>{{ r.description }}</b>
      </div>
    </ng-container>
    <ng-container *ngIf="!r.id">
      <button class="btn btn-primary btn-block mt-2 w-100" (click)="showAll($event)">
        {{ r.description }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #exploded>
  <i class="material-icons"> pedal_bike </i>
  <i class="material-icons position-absolute" style="right: 0px; top: -2.5px; transform: scale(0.6)">
    account_tree
  </i>
</ng-template>


<form class="d-flex w-100 justify-content-center justify-content-md-center" id="searchForm" name="searchForm"
  [ngClass]="['count-' + resultCount, focus ? 'showBg' : '']" (clickOutside)="
    instantResult.display = false; showAlternativeSuggestions = false
  ">
  <ng-content></ng-content>
  <div class="mb-2 me-0 col-md-6 col-lg-7 col-xl-7 col-9 col-sm-5 me-sm-4 mb-sm-0"
    [ngClass]="model.length || searchText.length > 0 ? 'has-text' : ''">
    <div class="row position-relative input-container">
      <div class="col-4 col-md-5 col-lg-5 position-relative" [ngClass]="selectedGroup?.product_group_codes !== null ? 'col-xl-5' : 'col-xl-4'"  (mouseenter)="scrollToGroup(groupstreesearchbar)" (clickOutside)="showTreeView = false">
        <div name="groupFilter" class="position-absolute selectGroup ps-3 pe-1 d-flex align-items-center" (click)="showTreeView = true" [class.showTreeView]="showTreeView">
          <span class="selected-group-label" [class.active]="selectedGroup?.product_group_codes !== null" [class.long]="selectedProductGroupDescription.length > 15"  *ngIf="groups?.length" [ngStyle]="{'font-size': selectedProductGroupDescription.length > 12 ? '0.75rem' : '0.9rem'}"><span class="scroll-text">{{selectedProductGroupDescription}}</span></span>
          <span class="remove-selected-group position-absolute h-100 d-flex align-items-center" *ngIf="selectedGroup?.product_group_codes !== null" (click)="groupstreesearchbar.resetProductGroups()">
            <b class="clickable withOpacity "
           >&times;</b>
          </span>
          <div class="spinner spinner-border spinner-border-sm" *ngIf="groupstreesearchbar.loading"> <span class="visually-hidden">{{'LOADING'|translate}}</span>
          </div>
        </div>
      <app-groups-tree-searchbar
        #groupstreesearchbar
        id="groups-tree-searchbar"
        [hidden]="treeGroupSelected"
        [groups]="groups"
        (groupSelected)="groupSelected($event)"
      ></app-groups-tree-searchbar>
      </div>
      <div class="col-8 col-md-7 col-lg-7 ps-0" [ngClass]="selectedGroup?.product_group_codes !== null ? 'col-xl-7' : 'col-xl-8'">
        <input id="typeahead-http" #searchInput type="text" class="ms-auto form-control me-sm-2 col-md-12 ps-1 pe-5"
          name="searchText" [focusFirst]="false" [resultTemplate]="rt"
          (selectItem)="onSelect($event)" [ngbTypeahead]="search" placeholder="" [inputFormatter]="formatter" #elem
          (click)="openTypeahead()" #instance="ngbTypeahead" (focus)="showResult(); showRecent(recent)"
          (keyup)="detectChange($event); recent.show = false" (clickOutside)="focus = false; recent.close($event)"
          [value]="model" [ngClass]="resultCount >= 5 ? 'showMore' : ''" />
        <button id="search-button" class="p-0 btn position-absolute input-icon w-auto" (click)="getResults()">
          <span class="material-icons" [ngClass]="searchDisabled ? 'disabled' : ''">search</span>
        </button>
        <b class="cross clickable withOpacity" (click)="resetSearch(searchInput, $event)"
          *ngIf="model.length || searchText.length > 0">&times;</b>
      </div>
    </div>
    <app-instant-result #instantResult [instantArticle]="instantArticle" [searchTerm]="model"
      (showArticle)="recent.close()"></app-instant-result>
    <app-recent-searched #recent (getResult)="getRecent($event)" [hidden]="resultCount > 0"
      (gotoItem)="itemSelectMode = true; gotoItem($event)"></app-recent-searched>
    <div class="p-0 dropdown-menu col-md-12 alternativeSuggestions" [style.display]="
        showAlternativeSuggestions &&
        suggestions &&
        suggestions.results &&
        suggestions.results.length && instantArticle !== null
          ? 'block'
          : ''
      ">
      <app-suggest-terms #suggestions [searchTerm]="model"
        (getResult)="gotoSuggestion($event); showAlternativeSuggestions = false">
      </app-suggest-terms>
    </div>
  </div>

</form>
<span class="position-absolute d-none d-sm-block weekNumber" placement="bottom" [ngbTooltip]="currentDate"
  *ngIf="showWeekNumber"><span class="material-icons me-1" style="transform: translateY(2.5px); font-size: 15px">
    today </span>{{ "WEEK" | translate }} {{currentWeekNumber}}</span>
