<div class="w-100 d-flex basketArticle checkoutArticle pt-1 px-2 px-sm-3 row" *ngIf="(!article.parent_id || !article.parent_found) || isChild" [ngClass]="[
    'status-' +
      article.item_details?.stock_status +
      (article.item_details?.always_order ? '_always_order' : ''), preorder ? 'preorder' : ''
  ]">
  <div class="col-2 col-sm-1 d-flex justify-content-center align-md-self-center align-xs-self-start ps-1">
    <span class="imgContainer h-100 text-center" (click)="openModal()">
      <app-article-image [imageUrl]="article.item_details?.thumbs[0]"
        [articleType]="article.item_details?.article_type" [description]="article.item_details?.description"
        [classList]="'img-thumbnail'" [useLazyLoad]="false"></app-article-image>
    </span>
  </div>
  <div class="col-md-4 col-10 details clickable withOpacity" (click)="openModal()">
    <span class="ps-2 d-inline-block fw-bold" [innerHTML]="article.item_details?.description"></span><br />
    <small
      class="ps-2 d-block art-code"
      [innerHTML]="
        article.article_code +
        ' ' +
        (article.item_details?.caption
          ? '- ' + article.item_details?.caption
          : '')
      "
    ></small>
    <small *ngIf="article.is_mandatory" class="text-danger d-block ps-2">{{ "REQUIRED_ITEM" | translate }}.
    </small>
    <small *ngIf="article.warning" class="text-danger d-block ps-2">{{
      article.warning
      }}</small>
    <small *ngIf="article.erp_message" class="text-danger d-block ps-2">{{
      article.erp_message
      }}</small>
    <span class="badge ps-2 ms-2" style="background-color: #eee" *ngIf="article.order_line_group">{{
      (article.order_line_group.indexOf("repair") >= 0 ? "REPAIR" : "DEMO")
      | translate
      }}</span>

    <div class="row d-sm-none">
      <app-stock-status class="col-3 mt-2" [showText]="false" *ngIf="!article.item_details?.setitem && !basketLocked"
        [article]="article.item_details" [ngbTooltip]="article.item_details | statusText"></app-stock-status>
      <app-delivery-week class="col-4 mt-2 px-0" [article]="article" [lockedDeliveryWeeks]="true"></app-delivery-week>
      <span class="col-5 ps-0 text-end pt-2"><span class="d-inline-block">{{ article["amount"] }} &times;&nbsp;
        </span><app-nett-price  *ngIf="article.price" [price]="article.price"
          class="price d-inline-block"></app-nett-price>
        <app-article-sum [netPrice]="article.price" [retailPrice]="article.item_details?.retail_price"
          class="price d-inline-block" [amount]="article['amount']"></app-article-sum>
      </span>
    </div>
  </div>
  <div class="col-md-1 col-1 d-none d-sm-block">
    <app-stock-status [showText]="false" *ngIf="!article.item_details?.setitem && !basketLocked"
      [article]="article.item_details">
    </app-stock-status>
    <div class="d-block">
      <small class="d-flex align-items-center indication" *ngIf="!showShopType && article.sold">
        <i class="material-icons small">check</i>
        <span>{{ "SOLD" | translate }}</span>
      </small>
      <small class="d-flex align-items-start indication" *ngIf="
              !showShopType &&
              article.backorder &&
              article.item_details?.stock_status === 3
            ">
        <i class="material-icons small">check</i>
        <span class="mb-1">{{ "BACKORDER_HEADER" | translate }}</span>
      </small>
      <ng-container *ngIf="showShopType">
        <span *ngIf="article.shopType === 'W'">{{
          "WORKSHOP" | translate
          }}</span>
        <span *ngIf="article.shopType === 'S'">{{ "SHOP" | translate }}</span>
        <span *ngIf="article.shopType === 'X'">{{ "SHOP" | translate }} {{ "SOLD" | translate }}</span>
      </ng-container>
    </div>
  </div>
  <div class="col-md-1 col-1 d-none d-sm-block px-0">
    <app-delivery-week [article]="article" [lockedDeliveryWeeks]="true" *ngIf="!readonly"></app-delivery-week>
    <ng-container *ngIf="readonly && article.delivery_year != '9999'">{{ article.delivery_week }} {{
      article.delivery_year
      }}</ng-container>
  </div>
  <div class="col-md-2 d-none d-sm-block hideForShowroomMode text-end px-md-0 px-lg-1 pt-1">
    <span class="d-inline-block">{{ article["amount"] }}
      <ng-container *ngIf="!article.item_details?.setitem && article.price">&times;
      </ng-container>
    </span>
    <app-nett-price [price]="article['price']" class="price d-inline-block ms-1"></app-nett-price>
    <app-retail-price [hideLabel]="true" [price]="article.item_details?.retail_price"
      *ngIf="article.item_details?.retail_price && showroomMode" class="price"></app-retail-price>
  </div>
  <div class="col-md-3 d-none d-sm-flex px-md-0 px-lg-1 ms-auto pt-1">
    <span class="hideForShowroomMode ms-auto pe-2">
      <app-article-sum [netPrice]="article['price']" [retailPrice]="article.item_details?.retail_price"
        class="price" [amount]="article['amount']"></app-article-sum>
    </span>
  </div>

  <div class="col-12 row pb-2" *ngIf="article.remarks">
    <div class="col-1"></div>
    <div class="col-4 d-none d-sm-flex align-items-center pt-1 px-2">
      <small class="small indication">
        <i *ngIf="article.remarks" class="material-icons" placement="top" [ngbTooltip]="article.remarks">
          chat_bubble_outline
        </i>
      </small>
      <small><i>{{ article.remarks }}</i></small>
    </div>
    <div class="col-4 d-none d-sm-block">

    </div>
  </div>
</div>
<div class="w-100 d-block mb-2 mt-1 px-0" *ngIf="article && article.children">
  <app-checkout-article [article]="article.children[0]" [parentArticle]="article"
    [isChild]="true"></app-checkout-article>
</div>
