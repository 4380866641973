import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PreparationInput } from "../interfaces/ebsc/preparation-input";
import { ServiceInput } from "../interfaces/ebsc/service-input";

@Injectable({
  providedIn: "root",
})
export class EbscService {
  constructor(private http: HttpClient) {}

  getEbikeRegistraton(frameNumber: string, checkPreparation?: boolean) {
    return this.http.get(
      environment.apiendpoint +
        `ebike/registrations/${frameNumber}${
          checkPreparation ? "?checkPreperation=true&checkExtra=false" : ""
        }`
    );
  }

  getEbikeRegistrations(type: string) {
    return this.http.get(
      environment.apiendpoint + `ebike/registrations/overview/${type}`
    );
  }

  prepareEbike(preparationIput: PreparationInput) {
    return this.http.post(
      environment.apiendpoint + `ebike/prepare`,
      preparationIput
    );
  }

  registerBike(preparationIput: PreparationInput) {
    return this.http.post(
      environment.apiendpoint + `bike/register/sale`,
      preparationIput
    );
  }

  registerEzyRider(preparationIput: PreparationInput) {
    return this.http.post(
      environment.apiendpoint + `ebike/xtrawarranty`,
      preparationIput
    );
  }

  getPrepareEbikePdf(frameNumber: string) {
    return this.http.get(
      environment.apiendpoint + `ebike/prepare/pdf/${frameNumber}?fileType=pdf`
    );
  }
  getEzyRiderEbikePdf(frameNumber: string, employee: string) {
    return this.http.get(
      environment.apiendpoint +
        `ebike/xtra/pdf/${frameNumber}/${employee}?fileType=pdf`
    );
  }

  registerService(serviceInput: ServiceInput) {
    return this.http.post(
      environment.apiendpoint + `bike/register/service`,
      serviceInput
    );
  }
}
