import { Component, OnInit, Input } from "@angular/core";
import { ArticleModalComponent } from "src/app/components/modal-components/article-modal/article-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Article } from "src/app/interfaces/article";
import { ApplicationService } from "src/app/services/application.service";
import { BasketService } from "src/app/services/basket.service";
import { BasketArticle } from "src/app/interfaces/basketArticle";

@Component({
  selector: "app-checkout-article",
  templateUrl: "./checkout-article.component.html",
  styleUrls: ["./checkout-article.component.scss"],
})
export class CheckoutArticleComponent implements OnInit {
  @Input() article: BasketArticle;
  @Input() isChild = false;
  @Input() parentArticle: BasketArticle;
  @Input() showShopType: boolean; // show shop type
  @Input() readonly: boolean;
  @Input() basketLocked: boolean;
  @Input() preorder: boolean;

  showroomMode: boolean;

  constructor(
    private ngbModal: NgbModal,
    private applicationService: ApplicationService,
    private basketService: BasketService
  ) {
    this.getShowroomMode();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(false);
  }

  ngOnInit() {
    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.basketService.closeBasketArticleModal();
  }

  openModal() {
    this.basketService.showBasketArticle(
      this.article.item_details,
      ArticleModalComponent
    );
  }
}
