import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb } from 'src/app/interfaces/bread-crumb';
import { AccountService } from 'src/app/services/account.service';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  page: string = ""
  companyGroupCode = "";
  showFinancialButtons = !this.applicationService.isPartnerPortal();
  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private accountService: AccountService
  ) {
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    if (window.location.href.includes('financial/invoices')) {
      this.page = "invoices"
      this.breadcrumbs = [
        new Breadcrumb(
          this.translateService.instant("HOME"),
          this.applicationService.getSelectCompanyGroupCode() + "/home"
        ),

        new Breadcrumb(
          this.translateService.instant("FINANCIAL"),
          this.applicationService.getSelectCompanyGroupCode() + "/financial"
        ),

        new Breadcrumb(
          this.translateService.instant("INVOICES"),
          this.applicationService.getSelectCompanyGroupCode() + "/financial/invoices"
        ),

        new Breadcrumb(this.translateService.instant("PAYMENT_SUCCESSFUL"), "#")
      ];
    } else if (window.location.href.includes('checkout') ) {
      this.page = "checkout"
      this.breadcrumbs = [
        new Breadcrumb(
          this.translateService.instant("HOME"),
          this.applicationService.getSelectCompanyGroupCode() + "/home"
        ),
        new Breadcrumb(this.translateService.instant("CHECKOUT"),'#'),
        new Breadcrumb(this.translateService.instant("PAYMENT_SUCCESSFUL"), "#")
      ];
    }


  }

  ngOnInit() {}
}
