import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Article } from 'src/app/interfaces/article';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ArticleModalComponent } from 'src/app/components/modal-components/article-modal/article-modal.component';
import { Location } from '@angular/common';
import { SearchService } from 'src/app/services/search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { ArticleCollectorService } from 'src/app/services/article-collector.service';
import { AccountService } from 'src/app/services/account.service';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0.5 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1, 'z-index': 0 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('scale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, 'z-index': 0 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ArticleCardComponent implements OnInit {
  @Input() article: Article;
  @Input() selected: boolean;
  @Input() leaseRequest: boolean;
  @Output() addToBasketButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showArticleDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() articleSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() articleCodeSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeFromList: EventEmitter<any> = new EventEmitter<any>();
  @Output() showDetailsInPopup: EventEmitter<any> = new EventEmitter<any>();
  @Input() autoShowDetails = false;
  @Input() useLazyLoad = true;
  @Input() specs: any;
  @Input() showAddButtons = true;
  @Input() hideOverlay = false;
  @Input() showWarrantyButton = false;
  @Input() hideMatrix = false;
  @Input() showBomButton = false;
  @Input() gotoDetailsPage = true;
  @Input() framenumber: string;
  @Input() animate: boolean;
  @Input() showModelYear: boolean;

  matrixOptions: any = [];
  selectedCompanyGroup = '';
  thumbnail = '';
  timer: any;
  hideImg = false;
  placeholder = 'assets/images/bicycle-solid.svg';
  thumbs = [];

  constructor(
    private modalService: NgbModal,
    private location: Location,
    private router: Router,
    private applicationService: ApplicationService,
    private articleCollectorService: ArticleCollectorService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.selectedCompanyGroup =
      this.applicationService.getSelectCompanyGroupCode();
    if (this.article !== null && typeof this.article !== 'undefined') {
      this.article.selected = this.selected;
      this.setThumbnail();

      // Checkbox changed
      this.articleCollectorService.changeEmitted$.subscribe(() => {
        this.articleChanged();
      });

      if (
        this.accountService.getAccountType() === 'DLR' &&
        this.applicationService.getSelectCompanyGroupCode() === 'WG'
      ) {
        this.showBomButton = false;
      }
      this.thumbs = this.article.thumbs;
    }
  }

  setThumbnail() {
    this.thumbnail = this.article.thumbs ? this.article.thumbs[0] : '';
  }

  openModal(article) {
    const modalRef = this.modalService.open(ArticleModalComponent, {
      size: 'lg',
      container: '#modalContainer',
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article['id'];
    modalRef.componentInstance.showDetailed = true;
    modalRef.componentInstance.showInModal = true;
    modalRef.componentInstance.selectedArticle = article['single_article_code'];
    modalRef.componentInstance.article.img = article['images'];
    this.showDetailsInPopup.emit(true)
    // modalRef.componentInstance.showInstant();
  }

  /**
   * Handles the navigation to the article details page or opens a modal based on conditions.
   * @param event The event triggering the navigation.
   * @param article The article object to display details for.
   */
  gotoItem(event, article) {
    if (this.gotoDetailsPage) {
      if (event.target.classList.contains('img-control') || event.target.classList.contains('show-volume-prices')) {
        return false;
      }
      const url =
        this.applicationService.getSelectCompanyGroupCode() +
        '/articleDetails/' +
        article.id +
        '/' +
        (article.single_article_code ? article.single_article_code : '') +
        (this.framenumber ? '?framenumber=' + this.framenumber : '');

      if (!window.location.href.includes("articleDetails")) {
        this.location.go(url);
      } else if ((window.location.href.includes("articleDetails") || window.location.href.includes(article.single_article_code)) && !window.location.href.includes(article.id)) {
        this.router.navigateByUrl("/RefreshComponent", { skipLocationChange: true })
          .then(() => {
            this.router.navigate([url]);
          });
      }
    } else {
      this.openModal(article);
    }

    this.showArticleDetails.emit(article);
    event.preventDefault();
    return false;
  }

  setHover(event) {
    if (!event.ctrlKey) {
      this.timer = setTimeout(() => {
        this.article['keyDown'] = true;
        this.animate = true;
      }, 350);
    }
  }

  removeHover() {
    clearTimeout(this.timer);
    this.article['keyDown'] = false;
  }

  getStockStatus() {
    return (
      'Status: ' + this.article.stock_status + ' ' + this.article.stock_date
    );
  }

  selectArticle(event) {
    if (
      (event.ctrlKey ||
        (event.srcElement &&
          event.srcElement.classList &&
          event.srcElement.classList.value.indexOf('selectForAdd') >= 0)) &&
      this.article.single_article_code
    ) {
      this.article.selected = this.article.selected ? false : true;
    }
    this.articleSelected.emit(this.article);
    this.articleCollectorService.countSelected(this.article);
  }

  // An article within Matrix is selected
  articleChanged() {
    let articleCodes = [];
    let thumbs = [];
    const articleCode = this.article.single_article_code;
    if (typeof this.article.matrix?.options !== 'undefined') {
      for (const option of this.article.matrix.options) {
        for (const article of option.value) {
          if (article.single_article_code === articleCode) {
            articleCodes = _.pluck(option.value, 'single_article_code');
          }
        }
      }
      for (const image of this.thumbs) {
        for (const code of articleCodes) {
          if (
            typeof code !== 'undefined' &&
            image.toLowerCase().includes(code.toLowerCase()) &&
            image.includes('_t')
          ) {
            thumbs.push(image);
          }
        }
      }
      if (thumbs.length) {
        this.article.thumbs = thumbs;
      }
    }
    this.articleCodeSelected.emit(this.article);
  }

  remove() {
    this.removeFromList.emit(this.article);
  }
}
