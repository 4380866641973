<table class="table ms-auto">
  <thead>
    <tr>
      <th scope="col">{{"BRAND"|translate}}</th>
      <th scope="col">{{"QUANTITY"|translate}}</th>
      <th scope="col">{{"TOTAL"|translate}}</th>
    </tr>
  </thead>
  <tbody *ngIf="basketArticlesByBrand && basketArticlesByBrand.length">
    <tr *ngFor="let article of basketArticlesByBrand">
      <td>{{article.brand}}</td>
      <td>{{article.quantity}}</td>
      <td>{{article.sum| currencyConvert: article.currency}}</td>
    </tr>
    <tr class="total">
      <td>{{"TOTAL"|translate}}</td>
      <td>{{getTotalQuantity()}}</td>
      <td>{{getTotalAmount()| currencyConvert: basketArticlesByBrand[0].currency}}</td>
    </tr>
  </tbody>
</table>
