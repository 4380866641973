import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.css"],
})
export class VideoComponent implements OnInit {
  @Input() url: string;
  @Input() max_width: string;
  @Input() min_height: string;
  @Input() _editable: any;
  @Input() _uid: any;
  @Input() component: any;
  isVideo: boolean;
  video_url: string;
  constructor() {}

  ngOnInit(): void {
    this.setUrl();
  }

  setUrl() {
    const url = this.url;
    if (url.includes("youtube")) {
      this.isVideo = true;
      this.video_url = `https://www.youtube.com/embed/${this.youtube_parser(
        url
      )}`;
    } else if (url.includes("vimeo")) {
      this.isVideo = true;
      this.video_url = `https://player.vimeo.com/video/${this.vimeo_parser(
        url
      )}`;
    } else if (url.includes("mp4")) {
      this.isVideo = true;
      this.video_url = url;
    }
    else {
      this.isVideo = false;
    }
  }

  youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  vimeo_parser(url) {
    var regExp =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    var match = url.match(regExp);
    return match[3];
  }
}
