<ng-container *ngIf="!lockedWeeks; else fixedDelivery">
<select class="form-control border" (change)="selectWeek($event)"  >
  <ng-container *ngFor="let weeks of monthList; let m = index">
    <optgroup [label]="getMonthDescription(weeks[0])" *ngIf="weeks.length && weeks[1].length">
      <ng-container *ngFor="let week of weeks[1]; let i = index; trackBy: weekValue">
        <option [value]="week.value" [selected]="(i == 0 && !deliveryWeek) && (m == 0 && !deliveryWeek) || (week.value === deliveryWeek)">{{week.text}}</option>
      </ng-container>
    </optgroup>
  </ng-container>
</select>
</ng-container>

<ng-template #fixedDelivery>
  <span class="d-block mt-2"
  >{{article | statusText:true}}</span>
</ng-template>
